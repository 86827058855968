import React, { useEffect, useState } from 'react'
import CommonLayout from '../../Layouts/CommonLayout/index'
import { Formik, Field, Form, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { Eye, EyeSlash } from 'iconsax-react'
import LogoutIcon from '@mui/icons-material/Logout'
import useLogin, { useForgotPassword, usePutToken } from '../../hook/Auth/useLogin'
import { jwtDecode } from "jwt-decode";
import { useNavigate } from 'react-router-dom'
import AppLogo from '../../common-images/app-logo/logo'
import HitronLogo from '../../common-images/hitron-logo/logo'
import hitronHeaderImg from '../../common-images/hitron-logo/small-logo.png'
import LoadingSpinner from '../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import { Box, Snackbar } from '@mui/material'
import { getColorCode } from '../../utils/helper'
import { SnackBarProps } from '../../components/Device-table/Provisioning'
import FooterSection from '../../Layouts/AnimatedLayout/FooterSection'
import { useSessionContex } from '../../SessionContex'
import MuiAlert from '@mui/material/Alert'
import VersionCheckModel from './VersionCheckModel'
import xcpemSec from '../../axiosInstances/xcpemSec'
import { versionCheckURL } from '../../app-config'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const LoginPage = () => {
  const loginMutation = useLogin()

  const { mutate: forgotMutation } = useForgotPassword()
  const { mutate: putToken } = usePutToken()
  const { openModal } = useSessionContex()

  const navigate = useNavigate()
  const [showPassword, setShowPassword] = useState(false)
  const [showVerifyVersionOpen, setShowVerifyVersionOpen] = useState(false)
  const [showResetPassword, setShowResetPassword] = useState(false)
  const [formKey, setFormKey] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(false)
  const [tAp, setTAP] = useState<boolean>(false)
  const [tApError, setTAPError] = useState('')
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })

  useEffect(() => {
    if (localStorage.getItem('access_token') && !openModal) {
      navigate('/dashboard')
    }
  }, [])
  const initialValues = showResetPassword ? { resetEmail: '' } : { email: '', password: '' }
  const emailVal =
    /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i
  const validationSchema = Yup.object(
    showResetPassword
      ? {
          resetEmail: Yup.string()
            .email('Please enter a valid email')
            .matches(emailVal, 'Invalid email')
            .required('Please enter your email')
        }
      : {
          email: Yup.string().email('Please enter a valid email').required('Please enter your email'),
          password: Yup.string().required('Please enter your password').matches(/^\S*$/, 'Spaces are not allowed')
        }
  )

  const toggleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleCheckVersionFunction = async (data: any) => {
    try {
      const response = await xcpemSec.get(versionCheckURL)
      if (response.data?.valid) {
        commonFunction(data)
      } else {
        setShowVerifyVersionOpen(true)
        setLoading(false)
      }
    } catch (error: any) {
      setLoading(false)
      setSnackBarInfo({
        isOpen: true,
        message: error?.message,
        type: 'redToRed',
        severity: 'error'
      })
    }
  }

  const commonFunction = (data: any) => {
    localStorage.setItem('access_token', data?.access_token)
    localStorage.setItem('refresh_token', data?.refresh_token)

    const decodeToken: any = jwtDecode(data?.access_token)
    console.log('@@', decodeToken['custom:issupport'])

    if (
      (decodeToken['custom:isadmin'] &&
        (decodeToken['custom:isadmin'] === 1 || decodeToken['custom:isadmin'] === '1')) ||
      (decodeToken['custom:issupport'] &&
        (decodeToken['custom:issupport'] === 1 || decodeToken['custom:issupport'] === '1'))
    ) {
      localStorage.setItem('userType', 'admin')
      localStorage.setItem('menuType', 'admin')
      localStorage.setItem('user', decodeToken?.name)
      localStorage.setItem('logged_user_email', decodeToken?.email)
      localStorage.setItem('user_id', decodeToken?.sub)
      localStorage.setItem('whiteLabel', decodeToken['custom:whitelabel'])
      if (
        decodeToken['custom:issupport'] &&
        (decodeToken['custom:issupport'] === 1 || decodeToken['custom:issupport'] === '1')
      ) {
        localStorage.setItem('deleteMSO', decodeToken['custom:issupport'])
      } else {
        localStorage.setItem('deleteMSO', '0')
      }
      putToken()
      setSnackBarInfo({
        isOpen: true,
        message: 'Login Successfully',
        type: 'Closed',
        severity: 'success'
      })
      setTimeout(() => {
        navigate('/dashboard')
        setLoading(false)
      }, 2000)
    } else {
      setLoading(false)
      localStorage.clear()
      localStorage.clear()
      setSnackBarInfo({
        isOpen: true,
        message: 'You do not have Administrator access',
        type: 'redToRed',
        severity: 'error'
      })
    }
  }

  const apiCall = (payload: any) => {
    setLoading(true)
    loginMutation.mutate(payload, {
      onSuccess: (data: any) => {
        if (process.env.REACT_APP_REALM !== 'Hitron-staging' && process.env.REACT_APP_REALM !== 'Customer') {
          commonFunction(data)
        } else {
          handleCheckVersionFunction(data)
        }
      },

      onError: (error: any) => {
        if (error.response && error.response.status === 401) {
          setLoading(false)
          localStorage.clear()
          localStorage.clear()
          setSnackBarInfo({
            isOpen: true,
            message: error.response.data.error_description,
            type: 'redToRed',
            severity: 'error'
          })
        }
        setLoading(false)
      }
    })
  }

  const onSubmit = (values: any, { setValues }: any) => {
    //---- onLogin start ------

    if (showResetPassword) {
      setLoading(true)
      forgotMutation(values?.resetEmail, {
        onSuccess: (status: any) => {
          if (status === 204)
            setSnackBarInfo({
              isOpen: true,
              message:
                'If the email you provided is already registered with us, a password reset link will be sent to that email address.',
              type: 'Closed',
              severity: 'success'
            })
          setTimeout(() => {
            setLoading(false)
            setShowPassword(!showPassword)
            setShowResetPassword(!showResetPassword)
            setValues({ email: values?.resetEmail })
            // navigate('/dashboard')
          }, 2000)
        },
        onError: (error: any) => {
          if (error.response) {
            setLoading(false)
            setSnackBarInfo({
              isOpen: true,
              message: error.response?.data?.error_description,
              type: 'redToRed',
              severity: 'error'
            })
          }
          setLoading(false)
        }
      })
    } else {
      //---Authentication API Calling---
      const payload = {
        email: values.email,
        password: values.password
      }
      if (process.env.REACT_APP_MODE !== 'local') {
        if (tAp) {
          setTAPError('')
          apiCall(payload)
        } else {
          setTAPError('Please read terms of use and privacy policy')
        }
      } else {
        apiCall(payload)
      }
    }
  }

  const onClickResetPassword = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    setShowResetPassword(!showResetPassword)
    setFormKey(Date.now())
    setTAP(false)
    setTAPError('')
  }
  // console.log('========form', formKey)

  return (
    <CommonLayout>
      <div
        className={`${process.env.REACT_APP_MODE !== 'local' ? 'with-footer' : 'with-out-footer'} login-page-wrapper`}
      >
        <div className='login-card'>
          <div className='login-card-logo'>
            {process.env.REACT_APP_MODE === 'local' && <AppLogo />}
            {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM !== 'Hitron' && <HitronLogo />}
            {process.env.REACT_APP_MODE === 'hitron' && process.env.REACT_APP_REALM === 'Hitron' && (
              <img src={hitronHeaderImg} alt='' width={'100px'} style={{ margin: 'auto' }} />
            )}
          </div>
          <div className='login-card-text'>
            <p>{showResetPassword ? 'Please enter your email' : 'Please enter your credentials'}</p>
          </div>
          <Formik key={formKey} initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {formik => (
              <Form>
                {showResetPassword ? (
                  //---Reset password Page---
                  <>
                    <label className='login-card-label d-block' htmlFor='resetEmail'>
                      Email
                      <Field
                        className={`login-card-input ${process.env.REACT_APP_MODE}-input`}
                        type='email'
                        id='resetEmail'
                        name='resetEmail'
                        data-testid='testid_reset_email'
                      />
                    </label>
                    <ErrorMessage
                      name='resetEmail'
                      component='div'
                      className={`${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'HitronErrorMessage'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'CustomerErrorMessage'
                          : 'ErrorMessage'
                      }`}
                    />
                    <div className={`login-card-passwordForgot ${process.env.REACT_APP_MODE}-color`}>
                      <p
                        onClick={(e: any) => {
                          if (loading) {
                            return null
                          } else {
                            onClickResetPassword(e)
                          }
                        }}
                      >
                        Back to Login?
                      </p>
                    </div>
                    <button
                      className={`login-card-resetSubmit ${process.env.REACT_APP_MODE}-button`}
                      style={{ textTransform: 'uppercase' }}
                      type='submit'
                      data-testid='testid_reset_password'
                    >
                      {loading ? (
                        <div className='w-full d-flex justify-center'>
                          <LoadingSpinner />
                        </div>
                      ) : (
                        <> Reset Password</>
                      )}
                    </button>
                  </>
                ) : (
                  //---Login Page---
                  <>
                    <label className='login-card-label d-block' htmlFor='email'>
                      Email
                      <Field
                        className={`login-card-input ${process.env.REACT_APP_MODE}-input`}
                        type='email'
                        id='email'
                        name='email'
                        data-testid='testid_email'
                      />
                    </label>
                    <ErrorMessage
                      name='email'
                      component='div'
                      className={`${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'HitronErrorMessage'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'CustomerErrorMessage'
                          : 'ErrorMessage'
                      }`}
                    />
                    <label className='login-card-label' htmlFor='password'>
                      Password
                    </label>
                    <div className='relative'>
                      <Field
                        className={`login-card-input ${process.env.REACT_APP_MODE}-input`}
                        type={showPassword ? 'text' : 'password'}
                        id='password'
                        name='password'
                        data-testid='testid_password'
                      />
                      <span className=' login-card-passwordIcon' onClick={toggleShowPassword}>
                        {!showPassword ? <Eye size='24' /> : <EyeSlash size='24' />}
                      </span>
                    </div>
                    <ErrorMessage
                      name='password'
                      component='div'
                      className={`${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'HitronErrorMessage'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'CustomerErrorMessage'
                          : 'ErrorMessage'
                      }`}
                    />
                    {process.env.REACT_APP_MODE !== 'local' && (
                      <div className='mt-2 mb-2'>
                        <label>
                          <Field
                            type='checkbox'
                            name='tAp'
                            className='mr-5'
                            onClick={(e: any) => {
                              setTAP(e.target.checked)
                              !e?.target?.checked && setTAPError('Please read terms of use and privacy policy')
                              e?.target?.checked && setTAPError('')
                            }}
                            data-testid='testid_login_checkbox'
                          />
                          By logging in, you confirm that you agree to our{' '}
                          <a
                            href='https://us.hitrontech.com/legal/terms-of-use/'
                            target='_blank'
                            rel='noreferrer'
                            className='text-blue-800'
                          >
                            Terms of Use
                          </a>{' '}
                          and{' '}
                          <a
                            href='https://us.hitrontech.com/legal/privacy-policy/'
                            target='_blank'
                            rel='noreferrer'
                            className='text-blue-800'
                          >
                            Privacy Policy
                          </a>
                        </label>
                        {tApError !== '' && (
                          <div
                            className={`${
                              process.env.REACT_APP_COLOR === 'hitron'
                                ? 'HitronErrorMessage'
                                : process.env.REACT_APP_COLOR === 'customer'
                                ? 'CustomerErrorMessage'
                                : 'ErrorMessage'
                            } mt-1`}
                          >
                            {tApError}
                          </div>
                        )}
                      </div>
                    )}

                    <div className={`login-card-passwordForgot ${process.env.REACT_APP_MODE}-color`}>
                      <p
                        onClick={(e: any) => {
                          if (loading) {
                            return null
                          } else {
                            onClickResetPassword(e)
                          }
                        }}
                        data-testid='testid_forgot_password'
                      >
                        Forgot Password?
                      </p>
                    </div>
                    <div className='d-flex justify-center items-center'>
                      {/* {(formik.errors.email || formik.errors.password || !tAp) && <>======</>} */}
                      <button
                        className={`login-card-submit ${process.env.REACT_APP_MODE}-button`}
                        type='submit'
                        style={{ textTransform: 'uppercase' }}
                        disabled={
                          process.env.REACT_APP_MODE !== 'local'
                            ? !!(formik?.errors?.email || formik?.errors?.password) || !tAp
                            : !!(formik?.errors?.email || formik?.errors?.password)
                        }
                        data-testid='testid_login_submit'
                      >
                        {loading ? (
                          <div className='w-full d-flex justify-center'>
                            <LoadingSpinner />
                          </div>
                        ) : (
                          <>
                            <LogoutIcon />
                            Login
                          </>
                        )}
                      </button>
                    </div>
                  </>
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      {(process.env.REACT_APP_MODE !== 'local' || process.env.REACT_APP_REALM === 'Customer') && (
        <FooterSection className='bg-f0f0f3' />
      )}

      {snackBarInfo.isOpen && (
        <Snackbar
          open={snackBarInfo.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() =>
            setSnackBarInfo({
              isOpen: false,
              message: snackBarInfo.message,
              type: snackBarInfo.type,
              severity: 'success'
            })
          }
          sx={{ backgroundColor: 'transparent' }}
        >
          <div>
            <Alert
              severity={snackBarInfo.severity}
              sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
              data-testid='testid_login_message'
            >
              {snackBarInfo.message}
            </Alert>
          </div>
        </Snackbar>
      )}
      <VersionCheckModel isOpen={showVerifyVersionOpen} setShowVerifyVersionOpen={setShowVerifyVersionOpen} />
    </CommonLayout>
  )
}

export default LoginPage
