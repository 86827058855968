import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Snackbar, Button } from '@mui/material'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import MuiAlert from '@mui/material/Alert'
import { Modal } from 'antd'
import CloseIcon from '@mui/icons-material/Close'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import { useConnectedDevices } from '../../../hook/useConnectedDevices'
import SpeedTestCharts from './speed-test-charts'
import Loader from '../../../components/Loader'
import { SnackBarProps } from '../../../components/Device-table/Provisioning'
// import { useAccountGateways } from '../../../hook/useAccountGateways'
import { getColorCode } from '../../../utils/helper'
import { handlefingerprint } from '../../../utils/Teame/fingerprinting'
import { useSessionContex } from '../../../SessionContex'

dayjs.extend(utc)
dayjs.extend(timezone)

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const SpeedTest = ({ sendMqttMessage, mqttRes }: any) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const [mqtt, setMqtt] = useState<any>(null)
  const [isPageLoading, setIsPageLoading] = useState(true)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [activeGateway, setActiveGateway] = useState<string | null>(activeGw_Uid)
  const [activeId, setActiveId] = useState<string | null>('')
  const [deviceData, setDeviceData] = useState<any>([])
  const [processingSpeedtestCmd, setProcessingSpeedtestCmd] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })

  const {
    data: gatewayInfo,
    isLoading: gatewayInfoLoading,
    refetch,
    isFetching: gateWayFetching
  } = useGatewayInfo(activeGw_Uid)
  // const { data: deviceInfo, isLoading: deviceLoading, refetch: deviceRefetch } = useAccountGateways()
  const userGateways: any = useSelector((state: any) => state.deviceStatistics.userGateways)
  const ab: any = useSelector((state: any) => state.deviceStatistics)

  const conId =
    gatewayInfo && gatewayInfo?.dev_mesh_role === 'NonCAP' ? gatewayInfo?.parent_gw_uid : gatewayInfo?.gw_uid
  const lastSeenDate =
    gatewayInfo && !gatewayInfo?.is_online
      ? dayjs.utc(gatewayInfo?.last_seen).format(`YYYYMMDDTHH:mm:ss.000000`)
      : new Date().toISOString()
  const {
    data,
    isLoading,
    refetch: connectionRefetch,
    isFetching
  } = useConnectedDevices(
    conId,
    //  activeGw_Uid,
    lastSeenDate
  )

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  useEffect(() => {
    if (reCall) {
      refetch()
      setActiveId('')
      // connectionRefetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isPageLoading) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isPageLoading])

  useEffect(() => {
    setIsPageLoading(true)
    if (gatewayInfo === 401) {
      !openModal && setOpenModal(true)
    } else {
      if (
        gatewayInfo &&
        (gatewayInfo?.dev_mesh_role === 'CAP' || (gatewayInfo?.dev_mesh_role !== 'CAP' && gatewayInfo?.is_online))
      ) {
        setActiveGateway(gatewayInfo?.gw_uid)
        gatewayInfo && gatewayInfo?.gw_uid && connectionRefetch()
        // const initializeMqtt = async () => {
        //   const JsMqttClientImpl = (await import('../../../mqtt/index')).JsMqttClientImpl
        //   setMqtt(new JsMqttClientImpl())
        // }
        // initializeMqtt()
        setConnectedToMqtt(false)
      }
    }
  }, [gatewayInfo])

  useEffect(() => {
    if (activeGw_Uid) {
      setActiveId('')
      refetch()
      if (ab && !ab?.deviceGatewayLoading) {
        setDeviceData(userGateways)
      }
    }
  }, [activeGw_Uid])
  // useEffect(() => {
  //   // if (userGateways) {
  //   if (!ab?.deviceGatewayLoading) {
  //     console.log('==========', ab.deviceGatewayLoading)
  //     setDeviceData(userGateways)
  //   }
  //   // }
  // }, [ab])

  useEffect(() => {
    if (
      gatewayInfo
      // && gatewayInfo?.is_online
    ) {
      if (isFetching || gateWayFetching || gatewayInfoLoading || isLoading) {
        setIsPageLoading(true)
      } else {
        setTimeout(() => {
          setIsPageLoading(false)
        }, 1500)
      }
    }
    // else {
    //   console.log('=======>>@@', gatewayInfo, dayjs.utc(gatewayInfo?.last_seen).format(`YYYYMMDDTHH:mm:ss.000000`))
    //   setTimeout(() => {
    //     setIsPageLoading(false)
    //   }, 1500)
    // }
  }, [isFetching, gateWayFetching, gatewayInfoLoading, isLoading, gatewayInfo])

  const handleActiveGateway = (gwuid: string) => {
    setProcessingSpeedtestCmd(true)
    setActiveGateway(gwuid)
  }

  // mqtt

  const speedtest = JSON.stringify({
    cmd: 'speedtest',
    response_topic: 'speedtest'
  })

  // async function sendCommand(message: any, topic: string) {
  //   // await mqtt.sendMessage({ message, topic })
  //   sendMqttMessage(message, topic)
  // }

  // mqtt
  const handleSubscribe = (gwuid2: string, addParent: string) => {
    setOpen(true)
    if (!gwuid2) return
    // setTimeout(() => {
    //   setProcessingSpeedtestCmd(true)
    // }, 2000)
    // let topic = `/router/${gwuid2}/command`
    // let topic2 = `/router/${gwuid2}/resp`
    // let topic3 = `/router/${gwuid2}/site_survey`
    // if (gwuid2 !== '') {
    //   topic = `/router/${gwuid2}/command`
    //   topic2 = `/router/${gwuid2}/resp`
    //   topic3 = `/router/${gwuid2}/site_survey`

    //   mqtt.subscribeToTopics([topic2, topic, topic3])
    //   // mqtt.sendMessage({ message, topic })
    // }

    sendMqttMessage(speedtest, gwuid2, addParent)
    // sendCommand(speedtest, topic)
    // setSnackBarInfo({
    //   isOpen: true,
    //   message: 'Speed test command has been sent to the router',
    //   subMessage: 'Please revisit /refresh the page after 3 minutes to see the results',
    //   type: 'Closed'
    // })
    // mqtt.subscribeToTopics([topic2, topic, topic3])
    // mqtt.sendMessage({ message, topic })
  }
  const handleMqttStrem = (gwuid2: any) => {
    setActiveId(gwuid2.gw_uid)
    console.log('============>>@@$$', gatewayInfo, gwuid2)

    if (gwuid2.mesh_role === 'satellite') {
      handleSubscribe(
        gwuid2.gw_uid,
        gatewayInfo?.dev_mesh_role !== 'CAP' ? gatewayInfo?.parent_gw_uid : gatewayInfo.gw_uid
      )
    } else {
      handleSubscribe(gwuid2.gw_uid, '')
    }
    //new
    // if (!connectedToMqtt) {
    //   mqtt
    //     ?.connect()
    //     .then(() => {
    //       setConnectedToMqtt(true)

    //       handleSubscribe(gwuid2)
    //     })
    //     .catch((err: any) => {
    //       mqtt.disconnect()
    //     })
    // } else {
    //   handleSubscribe(gwuid2)
    // }
  }
  const funDevice = (item: any) => {
    return userGateways?.filter((items: any) => items.gw_uid === item.gw_uid)
  }

  return (
    <div className='speedTestWrapper'>
      {isPageLoading || ab?.deviceGatewayLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {gatewayInfo?.dev_mesh_role !== 'CAP' && !gatewayInfo?.is_online ? (
            <></>
          ) : (
            <div className='deviceListWrapper'>
              {data &&
                data.length > 0 &&
                data
                  ?.filter((item: any) => item.mesh_role !== null)
                  .sort((a: any, b: any) => {
                    if (a.mesh_role === 'master' && b.mesh_role !== 'master') {
                      return -1
                    } else if (a.mesh_role !== 'master' && b.mesh_role === 'master') {
                      return 1
                    } else {
                      return 0
                    }
                  })
                  .map((item: any, index: number) => {
                    const idForMqtt: any = (!open && activeId !== '' && item.gw_uid === activeId) || false

                    let data1 = funDevice(item)

                    const folder: any = process.env.REACT_APP_TITLE

                    const fingerprintPayload = [
                      item?.os_name,
                      item?.device_name,
                      item?.fingerbank_device_name,
                      item?.os_parent_name,
                      item?.manufacturer_name
                      // item?.model
                    ]
                    const image = item?.model?.toLowerCase()?.includes('aria')
                      ? 'aria'
                      : handlefingerprint(fingerprintPayload)

                    if (data1.length !== 0) {
                      if (data1[0].dev_mesh_role === 'NonCAP' && !data1[0].is_online) {
                        return null
                      } else {
                        return (
                          <div key={`active_router_${index}`} className='deviceWrapper'>
                            <div
                              className={`device-box bg-white py-6 px-2 relative rounded-[10px] shadow-md border-2 text-center `}
                              style={{
                                border: `${activeGateway === item.gw_uid
                                    ? `2px solid ${getColorCode('redToBlue')}`
                                    : '2px solid #e3e3e3'
                                  }`
                              }}
                              onClick={() =>
                                data1[0]?.is_online
                                  ? activeGateway === item.gw_uid
                                    ? null
                                    : handleActiveGateway(item.gw_uid)
                                  : null
                              }
                            >
                              <div
                                style={{
                                  minHeight: '156px',
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center'
                                }}
                              >
                                <img
                                  src={`/images/network-map-${folder.toLowerCase() !== 'xunison' ? 'hitron-images' : 'images'
                                    }/${image === 'unknown' ? 'd50-home' : image}.png`}
                                  // src='/images/network-map-images/d50-home.png'
                                  className='device_image'
                                  alt=''
                                />
                              </div>
                              <span
                                className={`device_title mt-auto px-2`}
                                title={`${(item.mesh_role === 'master' ? item.title : item.ssid || item.title) || '-'}`}
                                style={{ color: getColorCode('redToBlue') }}
                              >
                                {(item.mesh_role === 'master' ? item.title : item.ssid || item.title) || '-'}
                              </span>
                              <span
                                className={`device_type absolute top-2 right-1 px-2 py-1 rounded-full font-sf-light text-xs`}
                                style={{
                                  backgroundColor: `${process.env.REACT_APP_COLOR === 'hitron' ||
                                      process.env.REACT_APP_COLOR === 'customer'
                                      ? getColorCode('redToCyan')
                                      : item.mesh_role === 'master'
                                        ? getColorCode('greenToGreen')
                                        : getColorCode('blueBlue')
                                    }`,
                                  color: `${process.env.REACT_APP_COLOR === 'hitron' ||
                                      process.env.REACT_APP_COLOR === 'customer'
                                      ? 'black'
                                      : 'white'
                                    }`
                                }}
                              >
                                {item.mesh_role === 'master' ? 'M' : 'S'}
                              </span>
                            </div>
                            <button
                              type='button'
                              disabled={data1[0]?.is_online ? idForMqtt : true}
                              // disabled={processingSpeedtestCmd}
                              className={`px-3 mt-2 device_picker disable py-1 text-white rounded-md`}
                              style={{
                                background: data1[0]?.is_online
                                  ? idForMqtt
                                    ? getColorCode('redToGray')
                                    : getColorCode('greenBlue')
                                  : getColorCode('grayToGray'),
                                color: `${process.env.REACT_APP_COLOR === 'hitron'
                                    ? '#fff'
                                    : activeId === item.gw_uid
                                      ? getColorCode('blackLightGreen')
                                      : '#fff'
                                  }`
                              }}
                              onClick={() => handleMqttStrem(item)}
                              data-testid={`testid_speed_test_${index}`}
                            >
                              {data1[0]?.is_online
                                ? isFetching && activeGateway === item.gw_uid
                                  ? 'Running...'
                                  : 'Run Speed Test'
                                : 'Device Offline'}
                            </button>
                          </div>
                        )
                      }
                    } else {
                      return (
                        <div key={`active_router_${index}`} className='deviceWrapper'>
                          <div
                            className={`device-box bg-white py-6 px-2 relative rounded-[10px] shadow-md border-2 text-center `}
                            style={{
                              border: `${activeGateway === item.gw_uid
                                  ? `2px solid ${getColorCode('redToBlue')}`
                                  : '2px solid #e3e3e3'
                                }`
                            }}
                            onClick={() => (activeGateway === item.gw_uid ? null : handleActiveGateway(item.gw_uid))}
                          >
                            <div
                              style={{
                                minHeight: '156px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center'
                              }}
                            >
                              <img
                                src={`/images/network-map-${folder.toLowerCase() !== 'xunison' ? 'hitron-images' : 'images'
                                  }/${image === 'unknown' ? 'd50-home' : image}.png`}
                                // src='/images/network-map-images/d50-home.png'
                                className='device_image'
                                alt=''
                              />
                            </div>
                            <span
                              className={`device_title mt-auto px-2`}
                              title={`${item.ssid || item.title || '-'}`}
                              style={{ color: getColorCode('redToBlue') }}
                            >
                              {item.ssid || item.title || '-'}
                            </span>
                            <span
                              className={`device_type absolute top-2 right-1 px-2 py-1 rounded-full font-sf-light text-xs`}
                              style={{
                                backgroundColor: `${process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer'
                                    ? getColorCode('redToCyan')
                                    : item.mesh_role === 'master'
                                      ? getColorCode('greenToGreen')
                                      : getColorCode('blueBlue')
                                  }`,
                                color: `${process.env.REACT_APP_COLOR === 'hitron' || process.env.REACT_APP_COLOR === 'customer'
                                    ? 'black'
                                    : 'white'
                                  }`
                              }}
                            >
                              {item.mesh_role === 'master' ? 'M' : 'S'}
                            </span>
                          </div>
                          <button
                            type='button'
                            disabled={
                              gatewayInfo.gw_uid === item.gw_uid
                                ? gatewayInfo?.is_online
                                  ? processingSpeedtestCmd
                                  : true
                                : true
                            }
                            className='px-3 mt-2 device_picker disable py-1 text-white rounded-md'
                            // style={{
                            //   background: getColorCode('greenBlue')
                            // }}
                            style={{
                              background:
                                gatewayInfo.gw_uid === item.gw_uid
                                  ? gatewayInfo?.is_online
                                    ? idForMqtt
                                      ? getColorCode('redToGray')
                                      : getColorCode('greenBlue')
                                    : getColorCode('grayToGray')
                                  : getColorCode('greenBlue'),
                              color: `${process.env.REACT_APP_COLOR === 'hitron'
                                  ? '#fff'
                                  : activeId === item.gw_uid
                                    ? getColorCode('blackLightGreen')
                                    : '#fff'
                                }`
                            }}
                            onClick={() => handleMqttStrem(item)}
                            data-testid={`testid_speed_test_${index}`}
                          >
                            {gatewayInfo.gw_uid === item.gw_uid
                              ? gatewayInfo?.is_online
                                ? isFetching && activeGateway === item.gw_uid
                                  ? 'Running...'
                                  : 'Run Speed Test'
                                : 'Device Offline'
                              : isFetching && activeGateway === item.gw_uid
                                ? 'Running...'
                                : 'Run Speed Test'}
                          </button>
                        </div>
                      )
                    }
                  })}
            </div>
          )}

          {gatewayInfo ? (
            //  && gatewayInfo?.is_online
            <div className='SpeedTestWrapper '>
              {activeGateway && data !== undefined && (
                <SpeedTestCharts
                  gwuid={activeGateway}
                  setProcessingSpeedtestCmd={setProcessingSpeedtestCmd}
                  processingSpeedtestCmd={processingSpeedtestCmd}
                  dataDevice={data}
                  setActiveId={setActiveId}
                  gatewayInfo={gatewayInfo}
                />
              )}
            </div>
          ) : (
            <div className='text-center text-[#6B7A9F] font-sf-regular text-lg animate-pulse py-5'>
              The speed test is not available as the device is offline. You can try again once the device is connected
            </div>
          )}
        </>
      )}
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type,
            severity: 'success'
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert
            severity={snackBarInfo.severity}
            sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
          >
            {snackBarInfo.message}
            {snackBarInfo.subMessage && <div>{snackBarInfo.subMessage}</div>}
          </Alert>
        </div>
      </Snackbar>
      <Modal
        open={open}
        footer={null}
        closable={false}
        // width={300}
        wrapClassName={'delete_notes_wrapper'}
        centered={true}
      >
        <div className='delete_notes_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3
              style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              Speed Test
            </h3>
            <CloseIcon
              className='cursor-pointer'
              onClick={() => {
                setOpen(false)
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            <div>
              <h2 style={{ fontSize: '1.25rem' }}> Speed test command has been sent to the router</h2>
            </div>
            <div className='mb-3 mt-1 text-lg'>
              Please revisit /refresh the page after couple of minutes to see the results
            </div>
            <div>
              <Button
                onClick={() => setOpen(false)}
                variant='contained'
                size='medium'
                sx={{
                  marginRight: '10px',
                  background: getColorCode('redToBlue'),
                  '&:hover': {
                    backgroundColor: getColorCode('redToBlue')
                  }
                }}
              // color='error'
              >
                Ok
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default SpeedTest
