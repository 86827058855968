import { useEffect, useState } from 'react'
import { ArrowRight2 } from 'iconsax-react'
import './index.scss'
import MuiAlert from '@mui/material/Alert'
import { Box, Grid, Button, FormControl, InputLabel, Select, MenuItem, Snackbar } from '@mui/material'
import { Progress } from 'antd'
import TableData from './TableData'
import DCChart from './DCChart'
import ResetModal from './ResetModal'
import ResetWifiPassword from './ResetWifiPassword'
import ChangePinModal from './ChangePinModal'
import SendCommandModal from './SendCommandModal'
import UpdateFirmware from './UpdateFirmware'
import {
  useDeleteSupervisions,
  useDeviceControlCount,
  useDeviceControlEventCount,
  useModalReset,
  useResetAccount,
  useSendMail,
  useSendMailPin,
  usefirmware
} from '../../../hook/device-control'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../redux/customerStatistics/types'
import dayjs from 'dayjs'
import { SnackBarProps } from '../../../components/Device-table/Provisioning'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { getColorCode } from '../../../utils/helper'
import { useSessionContex } from '../../../SessionContex'
import Loader from '../../../components/Loader'
import { useGatewayServices } from '../../../hook/useGatewayServices'

interface RouterConfig {
  SSID: string
  PASSWORD: string
  MESH_ROLE: string
}
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const initialLeftArray: any = [
  {
    id: 0,
    show: true,
    check: true,
    name: 'Factory Reset Router',
    des: 'Resetting to Factory default settings',
    modalTitle: 'Factory Reset Router',
    description: 'Are you sure you want to factory reset the router? This will revert all settings to default.',
    eventName: 'SYSRESET',
    // eventForAPI: 'in.(Admin GW SYSRESET,Batch GW SYSRESET)',
    eventForAPI: 'SYSRESET',
    api: 'webapp_gateway_sysreset',
    disabled: true,
    successMessage: 'Reset to factory settings successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_factory_reset_router'
  },
  {
    id: 1,
    show: true,
    check: true,
    name: 'Reboot Router',
    des: 'Rebooting Customer’s Router',
    modalTitle: 'Reboot Router',
    description: 'Are you sure you want to reboot the router?',
    eventName: 'REBOOT',
    // eventForAPI: 'in.(Batch GW REBOOT,Admin GW REBOOT)',
    eventForAPI: 'REBOOT',
    api: 'webapp_gateway_bulk_reboot',
    disabled: true,
    successMessage: 'Router rebooted successfull',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_reboot_router'
  },
  {
    id: 2,
    show: false,
    check: false,
    name: 'Reset WiFi Password',
    des: 'Resetting WiFi Password',
    modalTitle: 'Reset WiFi Password',
    eventName: 'WIFI_PASS_UPDATE',
    description: (
      <>
        Please enter a new WiFI password. <br />
        {/* Note: New Password will be sent to customer’s E-Mail. */}
      </>
    ),
    // eventForAPI: 'eq.Admin GW WIFI PASS UPDATED',
    eventForAPI: 'WIFI_PASS_UPDATE',
    api: 'webapp_gw_wifi_pass_reset',
    disabled: true,
    successMessage: 'WiFi Password reset successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_reset_wifi_password'
  },
  {
    id: 3,
    show: false,
    check: false,
    name: 'Reset Admin Panel Password',
    des: 'Resetting Admin panel Password',
    modalTitle: 'Reset Admin Panel Password',
    description: 'Please enter a new admin password for router panel',
    eventName: 'ADMIN_PASS_UPDATE',
    // eventForAPI: 'eq.Admin GW ADMIN PASS UPDATED',
    eventForAPI: 'ADMIN_PASS_UPDATE',
    api: 'webapp_gw_admin_panel_pass_reset',
    disabled: true,
    successMessage: 'Admin panel password reset successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_reset_admin_password'
  },
  {
    id: 4,
    show: false,
    check: false,
    name: 'Reset Parental Control',
    des: 'Resetting Parental Control settings',
    modalTitle: 'Reset Parental Control',
    eventName: 'RESET_PC',
    description:
      'Are you sure you want to reset Parental Control settings? Once you reset, all supervision data and applied blocking policies will be deleted. User PIN will also be reset to default value',
    // eventForAPI: 'eq.Admin GW SERVICE PC STATE SET',
    eventForAPI: 'RESET_PC',
    apiNew: 'resetaccount',
    // apiNew: 'account/reset',
    disabled: true,
    successMessage: 'Parental Control settings reset successfully',
    btnShow: false,
    provisionShow: true,
    testId: 'testid_reset_parental_control'
  },
  {
    id: 5,
    show: false,
    check: false,
    name: 'Reset Parental Control PIN',
    des: 'Resetting Parental Control PIN for End users',
    modalTitle: 'Reset Parental Control PIN',
    description: 'Please enter a new PIN for your customers Parental Control',
    eventName: 'RESET_PC_PIN',
    apiNew: 'resetpin',
    // apiNew: 'pin/reset',
    eventForAPI: 'RESET_PC_PIN',
    disabled: true,
    successMessage: 'Parental Control PIN reset successfully',
    btnShow: false,
    provisionShow: true,
    testId: 'testid_reset_pc_pin'
  },
  {
    id: 6,
    show: true,
    check: true,
    name: 'Enable SSH',
    des: 'Enabling SSH to Customer’s device',
    modalTitle: 'Enable SSH',
    description: 'Are you sure you want to enable SSH on this device?',
    eventName: 'SSH_START',
    // eventForAPI: 'eq.Admin SSH STARTED',
    eventForAPI: 'SSH_START',
    api: 'webapp_gw_ssh_enable',
    disabled: true,
    successMessage: 'Enabled SSH to customer device successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_enable_ssh'
  },
  {
    id: 7,
    show: true,
    check: true,
    name: 'Send Command to Router',
    des: 'Sending a Router command to Customer’s Router',
    modalTitle: 'Send command to Router',
    description: 'Please enter a payload',
    eventName: 'SEND_COMMAND',
    eventForAPI: 'Admin GW SEND COMMAND',
    mqtt: 'resp',
    disabled: true,
    successMessage: 'Send Payload Successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_send_command_router'
  },
  {
    id: 8,
    show: true,
    check: true,
    name: 'Update Firmware',
    des: 'Updating the FW version',
    modalTitle: 'Update Firmware',
    description: '',
    eventName: 'FW_UPDATE',
    // eventForAPI: 'in.(Batch FW UPDATED,Admin FW UPDATED)',
    eventForAPI: 'FW_UPDATE',
    api: 'webapp_gw_fw_update',
    disabled: true,
    successMessage: 'Firmware version updated successfully',
    btnShow: false,
    provisionShow: false,
    testId: 'testid_update_firmware'
  },
  {
    id: 9,
    show: false,
    check: false,
    name: 'Remove User from Gateway',
    des: 'Removing User from the Gateway',
    modalTitle: 'Remove User from Gateway',
    description: 'Are you sure you want to deprovision this email from this device/gateway?',
    eventName: 'DE-PROVISION',
    // eventForAPI: 'eq.Admin GW DE-PROVISIONED',
    eventForAPI: 'DE-PROVISION',
    api: 'webapp_dashboard_acc_remove_from_gw',
    disabled: false,
    successMessage: 'User removed from the Gateway successfully',
    btnShow: true,
    provisionShow: false,
    testId: 'testid_remove_user_gateway'
  }
]

const tableHeader: any = ['Date', 'Action', 'User', 'Device', 'GW Only', 'Status']

const DeviceControl = ({ sendMqttMessage, mqttRes, callMqtt }: any) => {
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { data: customerinfo, isFetching: gatewayFeaching, refetch: gatewayRefetch }: any = useGatewayInfo(activeGw_Uid)
  const { data: serviceInfo, refetch: refatchService } = useGatewayServices(activeGw_Uid)
  const { data: fData, refetch: fRefetch }: any = usefirmware(customerinfo?.model)
  const resetMutation = useModalReset()
  const sentMailMutation = useSendMail()
  const sentMailPinMutation = useSendMailPin()
  const deleteSupervisionsMutation = useDeleteSupervisions()
  const resetMutate = useResetAccount()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const [fwVersionData, setFwVersionData] = useState<any[]>([])
  const [leftArray, setLeftArray] = useState([...initialLeftArray])
  const [chartData, setChartData] = useState<any>([])
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [dataFilter, setDataFilter] = useState(24)
  const [selected, setSelected] = useState<number>(0)
  const [selectedID, setSelectedID] = useState<number>(0)
  const [isOpen, setIsOpen] = useState(false)
  const [chartLoader, setChartLoader] = useState(true)
  const [totalFailCount, setTotalFailCount] = useState(0)
  const [totalSucessCount, setTotalSucessCount] = useState(0)
  const [totalCount, setTotalCount] = useState(totalSucessCount + totalFailCount)
  const [recallApi, setRecallApi] = useState(false)
  const [mqtt, setMqtt] = useState<any>(null)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })

  const filteredLeftArray = serviceInfo?.srv_pc_enabled
    ? initialLeftArray
    : initialLeftArray.filter((item: any) => item.id !== 4 && item.id !== 5)

  // const topic = `/router/${activeGw_Uid}/command`
  // const topic2 = `/router/${activeGw_Uid}/resp`
  // const topic3 = `/router/${activeGw_Uid}/site_survey`

  // const message = JSON.stringify({
  //   cmd: 'getsysconfig',
  //   callid: 3,
  //   response_topic: 'resp',
  //   op: 'wizard'
  // })

  // async function sendCommand(message: any, topic: string) {
  //   await mqtt.sendMessage({ message, topic })
  // }

  const chartfromDate = dayjs
    .utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours'))
    .format('YYYYMMDDTHH:mm:ss.SSSSSS')
  // dayjs.tz(dayjs.utc().subtract(dataFilter, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss')

  // console.log(
  //   '======utc current',
  //   dayjs.utc(dayjs.tz(dayjs(), timeZone)).format('YYYY-MM-DD HH:mm:ss a'),
  //   '======utc subtract hours',
  //   dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours')).format('YYYY-MM-DD HH:mm:ss a')
  // )

  const initializeMqtt = async () => {
    // const JsMqttClientImpl = (await import('../../../mqtt')).JsMqttClientImpl
    // if (!mqtt) {
    //   setMqtt(new JsMqttClientImpl())
    // }
  }

  const { data, isFetching, refetch, isSuccess, isLoading }: any = useDeviceControlCount(
    activeGw_Uid,
    chartfromDate,
    leftArray[selected].eventName
  )
  const {
    data: eventData,
    isFetching: eventFeacting,
    refetch: eventRefetch,
    isSuccess: eventIsSuccess,
    isLoading: eventIsLoading
  }: any = useDeviceControlEventCount(
    dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours')).format('YYYY-MM-DDTHH:mm:ss'),
    leftArray[selected].eventName
  )

  useEffect(() => {
    gatewayRefetch()
    refatchService()
    displayData(0)
  }, [activeGw_Uid])

  useEffect(() => {
    customerinfo?.model && fRefetch()
  }, [customerinfo?.model])

  useEffect(() => {
    if (customerinfo !== undefined && fData?.length > 0) {
      // const foData = [...fData]
      // const liveData = foData.find((item: any) => item.fw_version === customerinfo?.fw_version)

      // if (!!liveData) {
      //   liveData['selected'] = true
      //   const filterData = foData.filter((item: any) => item.fw_version !== customerinfo?.fw_version)
      //   setFwVersionData([liveData, ...filterData])
      // } else {
      //   setFwVersionData([...foData])
      // }
      setFwVersionData(fData)
    }
  }, [fData, customerinfo])

  useEffect(() => {
    if (customerinfo === 401) {
      if (!openModal) {
        setOpenModal(true)
      }
      return // Exit early to prevent further processing
    }

    if (!!customerinfo && customerinfo?.model !== '') {
      initializeMqtt()

      // Only update leftArray if filteredLeftArray has changed
      setLeftArray(prevArray => {
        if (prevArray !== filteredLeftArray) {
          return filteredLeftArray.map((item: any) => {
            const newItem = {
              ...item,
              show: customerinfo?.dev_mesh_role === 'CAP',
              disabled: item?.disabled ? !customerinfo?.is_online : false,
              btnShow: item?.btnShow ? customerinfo?.email !== '' : true,
              provisionShow: item?.provisionShow ? customerinfo?.email !== '' : true
            }

            if (process.env.REACT_APP_REALM === 'Hitron-staging') {
              if (item?.id === 4) {
                newItem.apiNew = 'account/reset'
              } else if (item?.id === 5) {
                newItem.apiNew = 'pin/reset'
              }
            }

            return newItem
          })
        }
        return prevArray // Return previous state if no update
      })
    }
  }, [customerinfo, serviceInfo])

  useEffect(() => {
    if (reCall) {
      fRefetch()
      if (selectedID !== 7) {
        if (selectedID === 4 || selectedID === 5) {
          eventRefetch()
        } else {
          refetch()
        }
      }
      // refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!chartLoader) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [chartLoader])
  useEffect(() => {
    if (fData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [fData])

  // useEffect(() => {
  //   setConnectedToMqtt(false)
  //   //  setFetchingRouterSettings(true)
  //   if (customerinfo && customerinfo?.is_online && !connectedToMqtt) {
  //     mqtt
  //       ?.connect()
  //       .then(() => {
  //         setConnectedToMqtt(true)

  //         setSnackBarInfo({
  //           isOpen: true,
  //           message: 'connected to Router',
  //           type: 'chartPurpleBlue'
  //         })
  //         mqtt.subscribeToTopics([topic2, topic, topic3])
  //       })
  //       .catch((err: any) => {
  //         mqtt.disconnect()
  //         initializeMqtt()
  //         setSnackBarInfo({
  //           isOpen: true,
  //           message: 'Not connected to Router',
  //           type: 'redToRed'
  //         })
  //       })
  //   }
  // }, [activeGw_Uid, mqtt])

  // useEffect(() => {
  //   if (mqtt) {
  //     mqtt?.addMessageListener((message: any) => {
  //       const res = JSON.parse(message)
  //       // console.log('==========res', res)

  //       if (res?.name === 'sysconfig' && res?.callid === 0) {
  //         // if (res?.result === 'OK') {
  //         //   setSnackBarInfo({
  //         //     isOpen: true,
  //         //     message: 'Payload sent',
  //         //     type: 'Closed',
  //         //     severity: 'success'
  //         //   })
  //         // }
  //         if (res?.result === 'ERROR') {
  //           setSnackBarInfo({
  //             isOpen: true,
  //             message: res?.stderr_text || 'Command not sended',
  //             type: 'redToRed'
  //           })
  //         }
  //       }
  //     })
  //   }
  // }, [mqtt])

  // useEffect(() => {
  //   console.log('===========@@[]', selected)
  //   refetch()
  //   fRefetch()
  // }, [])

  useEffect(() => {
    if (recallApi) {
      // refetch()
      if (selectedID !== 7) {
        if (selectedID === 4 || selectedID === 5) {
          eventRefetch()
        } else {
          refetch()
        }
      }
    }
  }, [recallApi])

  useEffect(() => {
    setChartLoader(true)
    setTimeout(() => {
      setChartLoader(false)
    }, 1000)
  }, [isLoading, isFetching, eventFeacting, eventIsLoading])

  useEffect(() => {
    if (dataFilter === 24) {
      if (selectedID !== 7) {
        if (selectedID === 4 || selectedID === 5) {
          eventRefetch()
        } else {
          refetch()
        }
      }
    } else {
      setDataFilter(24)
    }
  }, [selectedID])

  useEffect(() => {
    // refetch()

    if (selectedID !== 7) {
      if (selectedID === 4 || selectedID === 5) {
        eventRefetch()
      } else {
        refetch()
      }
    }
  }, [dataFilter])

  useEffect(() => {
    const newData = selectedID === 4 || selectedID === 5 ? eventData : data

    if (data === 401 || eventData === 401) {
      !openModal && setOpenModal(true)
      setTimeout(() => {
        setChartLoader(false)
      }, 1000)
    } else if (isSuccess || eventIsSuccess) {
      if (selectedID === 4 || selectedID === 5) {
        if (process.env.REACT_APP_REALM === 'Hitron-staging') {
          if (newData) {
            const dataArray: any = Object.keys(newData).map(date => ({
              date: date,
              response_fail_count: newData[date].fail_count,
              success_count: newData[date].success_count
            }))

            const totalSuccessCount =
              dataArray && dataArray?.reduce((total: any, item: any) => total + item.success_count, 0)
            const totalFailCount =
              dataArray && dataArray?.reduce((total: any, item: any) => total + item.response_fail_count, 0)
            setTotalSucessCount(totalSuccessCount)
            setTotalFailCount(totalFailCount)
            setTotalCount(totalSuccessCount + totalFailCount)
            const sortedTimeArray: any =
              dataArray &&
              dataArray?.sort((a: any, b: any) => {
                let dateA: any = new Date(a.date)
                let dateB: any = new Date(b.date)
                return dateA - dateB
              })

            setChartData(sortedTimeArray)
            setTimeout(() => {
              setChartLoader(false)
            }, 1000)
          } else {
            setChartData([])
            setTotalSucessCount(0)
            setTotalFailCount(0)
            setTotalCount(0)
            setTimeout(() => {
              setChartLoader(false)
            }, 1000)
          }
        } else {
          if (newData && newData.Datewise_Counts) {
            const dataArray: any = Object.keys(newData?.Datewise_Counts).map(date => ({
              date: date,
              response_fail_count: newData?.Datewise_Counts[date].fail_count,
              success_count: newData?.Datewise_Counts[date].success_count
            }))

            const totalSuccessCount =
              dataArray && dataArray?.reduce((total: any, item: any) => total + item.success_count, 0)
            const totalFailCount =
              dataArray && dataArray?.reduce((total: any, item: any) => total + item.response_fail_count, 0)
            setTotalSucessCount(totalSuccessCount)
            setTotalFailCount(totalFailCount)
            setTotalCount(totalSuccessCount + totalFailCount)
            const sortedTimeArray: any =
              dataArray &&
              dataArray?.sort((a: any, b: any) => {
                let dateA: any = new Date(a.date)
                let dateB: any = new Date(b.date)
                return dateA - dateB
              })

            setChartData(sortedTimeArray)
            setTimeout(() => {
              setChartLoader(false)
            }, 1000)
          }
        }
      } else {
        const totalSuccessCount =
          newData &&
          newData?.reduce((total: any, item: any) => total + (item.total_count - item.response_fail_count), 0)
        const totalFailCount =
          newData && newData?.reduce((total: any, item: any) => total + item.response_fail_count, 0)
        setTotalSucessCount(totalSuccessCount)
        setTotalFailCount(totalFailCount)
        setTotalCount(totalSuccessCount + totalFailCount)
        const sortedTimeArray: any =
          newData &&
          newData?.sort((a: any, b: any) => {
            let dateA: any = new Date(a.date)
            let dateB: any = new Date(b.date)
            return dateA - dateB
          })

        setChartData(sortedTimeArray)
        setTimeout(() => {
          setChartLoader(false)
        }, 1000)
      }
    } else if (newData?.length === 0 || !isSuccess || !eventIsSuccess) {
      setTotalSucessCount(0)
      setTotalFailCount(0)
      setTotalCount(0)
      newData && newData?.length === 0 && setChartData([])
      setTimeout(() => {
        setChartLoader(false)
      }, 1000)
    }
  }, [data, isSuccess, eventData, eventIsSuccess])

  const handleDataFilter = (event: any) => {
    setDataFilter(event.target.value)
  }

  const displayData = (key: any) => {
    const index: any = leftArray.findIndex((item: any) => item.id === key)
    if (index !== -1) {
      setSelected(index)
    } else {
      setSelected(0)
    }
  }

  const closeModal = () => {
    setIsOpen(false)
  }
  const APICall = (data: any, url: any, payload: any) => {
    let newData: any = data
    let mailPayload: any
    if (leftArray[selected]?.id === 2) {
      mailPayload = {
        email: localStorage.getItem('email'),
        password: newData.password,
        gw_uuid: customerinfo?.gw_uid
      }
    }

    data &&
      resetMutation.mutate(
        { url, payload },
        {
          onSuccess: (data: any) => {
            if (data === 401) {
              !openModal && setOpenModal(true)
            }

            if (leftArray[selected]?.id === 2) {
              callMqtt()
              if (mailPayload.email === '') {
                // setTimeout(() => {
                //   setSnackBarInfo({
                //     isOpen: true,
                //     message: 'Email not available',
                //     type: 'redToRed',
                //     severity: 'error'
                //   })
                // }, 6500)
              } else {
                if (process.env.REACT_APP_MODE === 'hitron') {
                  sentMailMutation.mutate(
                    { mailPayload },
                    {
                      onSuccess: (data: any) => {
                        setSnackBarInfo({
                          isOpen: true,
                          message: 'Email notification will be sent to user on the reset password',
                          type: 'Closed'
                        })
                      },
                      onError: (error: any) => {
                        setSnackBarInfo({
                          isOpen: true,
                          message: error?.message,
                          type: 'redToRed',
                          severity: 'error'
                        })
                      }
                    }
                  )
                }
              }
            }
            setSnackBarInfo({
              isOpen: true,
              message: `${leftArray[selected].successMessage}`,
              type: 'Closed',
              severity: 'success'
            })

            setRecallApi(true)
            setTimeout(() => {
              setRecallApi(false)
            }, 1000)

            gatewayRefetch()
            closeModal()
            return 'OK'
          },
          onError: (error: any) => {
            setSnackBarInfo({
              isOpen: true,
              message: error?.message,
              type: 'redToRed',
              severity: 'error'
            })
            return 'ERROR'
          }
        }
      )
  }
  const onSubmit = async (data: any) => {
    if (leftArray[selected].mqtt) {
      // console.log('=========mqtt', leftArray[selected].mqtt, data)
      // setRouterNewConfig(null)
      // setFetchingRouterSettings(true)

      let dataJson: any = {
        callid: 0,
        op: '/usr/sbin/dropbear',
        response_topic: leftArray[selected].mqtt
      }

      if (leftArray[selected]?.id === 7) {
        dataJson = {
          ...dataJson,
          ...data
        }
      }
      const routerConfigPayload = JSON.stringify(dataJson)
      setIsOpen(false)
      setRecallApi(true)
      setTimeout(() => {
        setSnackBarInfo({
          isOpen: true,
          message: 'Payload sent',
          type: 'Closed',
          severity: 'success'
        })
        setRecallApi(false)
      }, 1000)

      gatewayRefetch()

      selectedID === 8 && fRefetch()

      // setIsApplyingConfig(false)
      // sendCommand(routerConfigPayload, topic)
      sendMqttMessage(routerConfigPayload, activeGw_Uid)

      // setTimeout(() => {
      //   const message = JSON.stringify({
      //     cmd: 'sysconfig',
      //     callid: 0,
      //     type: 'wizard'
      //   })
      //   sendCommand(message, topic)
      // }, 1000)
    }
    if (leftArray[selected].api) {
      let url = leftArray[selected].api
      let payload: any = {}
      if (leftArray[selected]?.id === 0 || leftArray[selected]?.id === 9) {
        payload = {
          _gw_uid: activeGw_Uid
        }
        APICall(data, url, payload)
      }
      if (leftArray[selected]?.id === 1) {
        const body = new URLSearchParams()
        // body.append('_gateway_list', JSON.stringify(obj))
        body.append('_gateway_list', `{${activeGw_Uid}}`)
        payload = body
        APICall(data, url, payload)
      }
      if (leftArray[selected]?.id === 8) {
        const body = new URLSearchParams()
        body.append('_gw_uid', activeGw_Uid)
        body.append('_fw_id', data)
        payload = body
        APICall(data, url, payload)
      }
      if (leftArray[selected]?.id === 2) {
        payload = {
          _gw_uid: activeGw_Uid,
          _2g_password: data.password,
          _5g_password: data.password
        }

        APICall(data, url, payload)
      }
      if (leftArray[selected]?.id === 3) {
        payload = {
          _gw_uid: activeGw_Uid,
          _user: 'admin',
          _password: data.password
        }
        APICall(data, url, payload)
      }
      if (leftArray[selected]?.id === 6) {
        payload = {
          _gw_uid: activeGw_Uid
        }
        APICall(data, url, payload)
      }
    }
    if (leftArray[selected].apiNew) {
      let url = leftArray[selected].apiNew

      const email = customerinfo?.email

      const payloadData = selectedID === 5 ? data : {}
      // const payloadData = {}
      const mailPayload = {
        email: localStorage.getItem('email'),
        pin: (data && data?.new_pincode) || '000000'
      }

      if (email) {
        resetMutate.mutate(
          { url, email, payloadData },
          {
            onSuccess: (data: any) => {
              if (data === 401) {
                !openModal && setOpenModal(true)
              }
              if (selectedID === 4 || selectedID === 5) {
                if (selectedID === 4) {
                  const payloadSupervision = {
                    email: email
                  }
                  deleteSupervisionsMutation.mutate(
                    { payloadSupervision },
                    {
                      onSuccess: (data: any) => {
                        console.log('========>>>@@@', data)

                        // setSnackBarInfo({
                        //   isOpen: true,
                        //   message: 'Email notification will be sent to user on the reset password',
                        //   type: 'Closed',
                        //   severity: 'success'
                        // })
                      },
                      onError: (error: any) => {
                        setSnackBarInfo({
                          isOpen: true,
                          message: error?.message,
                          type: 'redToRed',
                          severity: 'error'
                        })
                      }
                    }
                  )
                }
                sentMailPinMutation.mutate(
                  { mailPayload },
                  {
                    onSuccess: (data: any) => {
                      setSnackBarInfo({
                        isOpen: true,
                        message: 'Email notification will be sent to user on the reset password',
                        type: 'Closed',
                        severity: 'success'
                      })
                    },
                    onError: (error: any) => {
                      setSnackBarInfo({
                        isOpen: true,
                        message: error?.message,
                        type: 'redToRed',
                        severity: 'error'
                      })
                    }
                  }
                )
              }
              setSnackBarInfo({
                isOpen: true,
                message: `${leftArray[selected].successMessage}`,
                type: 'Closed',
                severity: 'success'
              })
              closeModal()
              setRecallApi(true)
              setTimeout(() => {
                setRecallApi(false)
              }, 1000)

              gatewayRefetch()
            },
            onError: (error: any) => {
              setSnackBarInfo({
                isOpen: true,
                message: error?.message,
                type: 'redToRed',
                severity: 'error'
              })
            }
          }
        )
      } else {
        setSnackBarInfo({
          isOpen: true,
          message: 'Email not available',
          type: 'redToRed',
          severity: 'error'
        })
      }
    }
  }

  const opemModal = (e: any) => {
    e.preventDefault()
    setIsOpen(true)
  }

  const renderContent = () => {
    switch (leftArray[selected]?.id) {
      case 0:
        return (
          <ResetModal
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            title={leftArray[selected]?.modalTitle}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 1:
        return (
          <ResetModal
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 2:
        return (
          <ResetWifiPassword
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            minValue={8}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 3:
        return (
          <ResetWifiPassword
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            minValue={2}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 4:
        return (
          <ResetModal
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 5:
        return (
          <ChangePinModal
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            title={leftArray[selected]?.modalTitle}
            subTitle={leftArray[selected]?.description}
          />
        )
      case 6:
        return (
          <ResetModal
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      case 7:
        return (
          <SendCommandModal
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
          />
        )
      case 8:
        return (
          <UpdateFirmware
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
            data={fwVersionData}
            customerinfo={customerinfo}
            testId={leftArray[selected]?.testId}
          />
        )
      case 9:
        return (
          <ResetModal
            title={leftArray[selected]?.modalTitle}
            isOpen={isOpen}
            onClose={closeModal}
            onSubmit={onSubmit}
            subTitle={leftArray[selected]?.description}
            testId={leftArray[selected]?.testId}
          />
        )
      default:
        return null
    }
  }

  return (
    <div className='device-control sec-color w-full'>
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert
            severity='success'
            sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
            data-testid='testid_alert_message'
          >
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
      <Grid container rowSpacing={1} sx={{ height: '100%' }}>
        <Grid item sm={12} lg={3} md={3} sx={{ height: '100%' }}>
          <div className='bg-white rounded-sm p-3' style={{ height: '100%' }}>
            <div className='font-bold mb-3 title'>Device control</div>
            <Box sx={{ display: 'flex', flexDirection: { md: 'column', sm: 'row' } }}>
              {leftArray?.map((obj: any, key: any) => {
                return (obj?.show && obj?.provisionShow) || obj?.check ? (
                  <div
                    key={obj?.id}
                    className={`flex items-center justify-between p-3 cursor-pointer ${
                      key === selected ? 'active-bg rounded' : 'bg-white'
                    }`}
                    onClick={() => {
                      displayData(obj?.id)
                      setSelectedID(obj?.id)
                    }}
                    data-testid={obj?.testId}
                  >
                    <div>
                      <div className='text-base font-bold'> {obj.name}</div>
                      <div className='text-sm '>{obj.des}</div>
                    </div>
                    <div>
                      <ArrowRight2 />
                    </div>
                  </div>
                ) : (
                  <></>
                )
              })}
            </Box>
          </div>
        </Grid>
        <Grid item sm={12} lg={9} md={9} sx={{ height: '100%' }}>
          <Box className='bg-white rounded-sm p-3 ml-3'>
            {leftArray[selected]?.disabled && !chartLoader && (
              <Button
                className={`w-full pl-2 curser`}
                sx={{
                  background: 'transparent',
                  color: getColorCode('infoBlue'),
                  cursor: 'default',
                  '&:hover': {
                    backgroundColor: 'transparent',
                    color: getColorCode('infoBlue')
                  }
                }}
              >
                Device is currently Offline. Cannot proceed with the Device actions.
              </Button>
            )}
            <div className='flex flex-wrap justify-between items-center mt-3'>
              <div className='font-bold mb-3 title'>{leftArray[selected]?.name}</div>
              {leftArray[selected]?.id !== 7 && (
                <div className='mb-3'>
                  {data && (
                    <FormControl fullWidth>
                      <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
                      <Select
                        labelId='demo-simple-select-label'
                        id='demo-simple-select'
                        value={dataFilter}
                        label='Filter'
                        onChange={handleDataFilter}
                        className='text-uppercase'
                      >
                        <MenuItem value={24} className='text-uppercase'>
                          Last 24 hours
                        </MenuItem>
                        <MenuItem value={168} className='text-uppercase'>
                          Last 7 Days
                        </MenuItem>
                        <MenuItem value={730} className='text-uppercase'>
                          Last 30 Days
                        </MenuItem>
                        <MenuItem value={2190} className='text-uppercase'>
                          Last 90 Days
                        </MenuItem>
                      </Select>
                    </FormControl>
                  )}
                </div>
              )}
            </div>
            <Box>
              {/* {leftArray[selected]?.id !== 7 &&
                (chartLoader ? (
                  <div className='d-flex w-full justify-center aline-center'>
                    <Loader />
                  </div>
                ) : (
                  <DCChart
                    data={chartData}
                    isFetching={selected === 4 || selected === 5 ? eventFeacting : isFetching}
                  />
                ))} */}
              {chartLoader ? (
                <div className='d-flex w-full justify-center aline-center'>
                  <Loader />
                </div>
              ) : (
                leftArray[selected]?.id !== 7 && (
                  <>
                    <DCChart
                      data={chartData}
                      isFetching={selectedID === 4 || selectedID === 5 ? eventFeacting : isFetching}
                      selected={selectedID}
                      totalCount={totalCount}
                    />
                    <div className='flex flex-wrap justify-between items-center border rounded p-3 mt-2'>
                      <div>
                        <div className='font-bold text-sm'>{leftArray[selected]?.name}</div>
                        <div className='font-bold title'>{totalCount || 0} Times</div>
                      </div>
                      <div className='flex items-center'>
                        <Box display={'flex'} alignItems={'center'} marginLeft={2} marginRight={2}>
                          <Progress
                            type='circle'
                            strokeColor={getColorCode('Online')}
                            percent={totalSucessCount}
                            format={number => ``}
                            strokeWidth={15}
                          />
                          <div className='font-bold ml-5' data-testid={leftArray[selected]?.testId + '_success'}>
                            <div className='text-sm'>Successful</div>
                            <div className='text-xl'>{totalSucessCount}</div>
                          </div>
                        </Box>
                        <Box display={'flex'} alignItems={'center'} marginLeft={2} marginRight={2}>
                          <Progress
                            type='circle'
                            strokeColor={getColorCode('redToRed')}
                            percent={totalFailCount}
                            format={number => ``}
                            strokeWidth={15}
                          />
                          <div className='font-bold ml-5' data-testid={leftArray[selected]?.testId + '_failed'}>
                            <div className='text-sm'>Failed</div>
                            <div className='text-xl'>{totalFailCount}</div>
                          </div>
                        </Box>
                      </div>
                    </div>
                  </>
                )
              )}
              {leftArray[selected]?.id !== 7 && (
                <TableData
                  headerTable={tableHeader}
                  customerinfo={customerinfo}
                  leftArray={leftArray}
                  selected={selected}
                  selectedID={selectedID}
                  dataFilter={dataFilter}
                  totalCount={totalCount}
                  recallApi={recallApi}
                />
              )}
              <div className='mt-3'>
                {leftArray[selected]?.btnShow && (
                  <Button
                    className={`w-full ${leftArray[selected]?.disabled ? 'coming-soon-cls' : ''}`}
                    // variant='contained'
                    sx={{
                      background: leftArray[selected]?.disabled ? getColorCode('grayToGray') : getColorCode('infoBlue'),
                      color: '#fff',
                      '&:hover': {
                        background: leftArray[selected]?.disabled
                          ? getColorCode('grayToGray')
                          : getColorCode('infoBlue')
                      }
                    }}
                    onClick={(e: any) => opemModal(e)}
                    disabled={leftArray[selected]?.disabled}
                    data-testid={leftArray[selected]?.testId + '_button'}
                  >
                    {leftArray[selected]?.name}
                  </Button>
                )}
              </div>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isOpen && renderContent()}
    </div>
  )
}
export default DeviceControl
