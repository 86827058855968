// import moment from 'moment/moment'
import moment from 'moment'
import dayjs from 'dayjs'
import Loader from '../../../components/Loader'
import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, useEffect, useState } from 'react'
import { useDeviceControlEventLogs, useDeviceControlFailLogs, useDeviceControlLogs } from '../../../hook/device-control'
import { useSessionContex } from '../../../SessionContex'
import { getColorCode } from '../../../utils/helper'
const TableData = ({
  headerTable,
  customerinfo,
  selected,
  leftArray,
  dataFilter,
  totalCount,
  recallApi,
  selectedID
}: any) => {
  // const [isLoading, setIsLoading] = useState(true)

  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const chartfromDate = dayjs
    .utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours'))
    .format('YYYYMMDDTHH:mm:ss.SSSSSS')
  //  dayjs
  //   .tz(dayjs.utc().subtract(dataFilter, 'hours'), timeZone)
  //   .format('YYYY-MM-DDTHH:mm:ss.SSSSSS')
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [successData, setSuccessData] = useState([])
  const [failData, setFailData] = useState([])
  const {
    data: eventData,
    isFetching: eventIsFetching,
    refetch: eventRefetch,
    isSuccess: eventSuccess
  }: any = useDeviceControlEventLogs(
    page * limit,
    limit,
    dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours')).format('YYYY-MM-DDTHH:mm:ss'),
    leftArray[selected].eventForAPI
  )
  const {
    data: logData,
    isFetching: logIsFetching,
    refetch: logRefetch,
    isSuccess
  }: any = useDeviceControlLogs(
    page * limit,
    customerinfo?.gw_uid,
    limit,
    chartfromDate,
    leftArray[selected].eventForAPI
  )
  const {
    data: logFailData,
    isFetching: logFailIsFetching,
    refetch: logFailRefetch,
    isSuccess: failSucess
  }: any = useDeviceControlFailLogs(
    page * limit,
    customerinfo?.gw_uid,
    limit,
    // dayjs.tz(dayjs.utc(chartfromDate).subtract(22, 'hours'), timeZone).format('YYYYMMDDTHH:mm:ss'),
    chartfromDate,
    leftArray[selected].eventForAPI
  )

  // useEffect(() => {
  //   if (selected === 4 || selected === 5) {
  //     eventRefetch()
  //   } else {
  //     logRefetch()
  //     logFailRefetch()
  //   }
  // }, [])

  useEffect(() => {
    if (recallApi) {
      // console.log('=============@@[recallApi]', selected)

      if (selectedID === 4 || selectedID === 5) {
        eventRefetch()
      } else {
        logRefetch()
        logFailRefetch()
      }
    }
  }, [recallApi])
  useEffect(() => {
    if (reCall) {
      // console.log('=============@@[recall]', selected)
      if (selectedID === 4 || selectedID === 5) {
        eventRefetch()
      } else {
        logRefetch()
        logFailRefetch()
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!(logIsFetching || logFailIsFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [logIsFetching, logFailIsFetching])

  useEffect(() => {
    if (logData === 401 || eventData === 401) {
      !openModal && setOpenModal(true)
    }
    if (selectedID === 4 || selectedID === 5) {
      if (eventSuccess) {
        if (process.env.REACT_APP_REALM === 'Hitron-staging') {
          if (eventData?.length !== 0) {
            eventData &&
              eventData?.forEach((obj: any) => {
                obj.event = obj.event_type
                obj.email = obj.action_user_email
                obj.timestamp = obj.event_time
                obj.gw_uid = obj.gw_uid || '-'
              })
          }
          setSuccessData(eventData)
        } else {
          if (eventData?.rows?.length !== 0) {
            eventData &&
              eventData.rows &&
              eventData?.rows?.forEach((obj: any) => {
                obj.event = obj.event_type
                obj.email = obj.action_user_email
                obj.timestamp = obj.event_time
                obj.gw_uid = obj.gw_uid || '-'
              })
          }
          setSuccessData(eventData?.rows)
        }
      } else {
        setSuccessData([])
      }
    } else {
      if (isSuccess) {
        setSuccessData(logData)
      } else {
        setSuccessData([])
      }
    }
  }, [logData, isSuccess, eventData, eventSuccess])

  useEffect(() => {
    if (logFailData === 401) {
      !openModal && setOpenModal(true)
    }
    if (selectedID === 4 || selectedID === 5) {
      setFailData([])
    } else {
      if (failSucess) {
        setFailData(logFailData)
      } else {
        setFailData([])
      }
    }
  }, [logFailData, failSucess, eventSuccess])
  useEffect(() => {
    if (selectedID === 4 || selectedID === 5) {
      eventRefetch()
    } else {
      logRefetch()
      logFailRefetch()
    }
  }, [page, limit])
  useEffect(() => {
    if (page === 0 && limit === 10) {
      // console.log('=============@@[selected, dataFilter]', selected, page)
      if (selectedID === 4 || selectedID === 5) {
        eventRefetch()
      } else {
        logRefetch()
        logFailRefetch()
      }
    } else {
      setPage(0)
      setLimit(10)
    }
  }, [selectedID, dataFilter])

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setPage(0)
  }

  return (
    <div className='w-full'>
      {logIsFetching || logFailIsFetching || eventIsFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <table className='w-full mt-5'>
          <thead>
            <tr>
              {headerTable?.map((obj: any, key: any) => {
                return <td key={key}>{obj}</td>
              })}
            </tr>
          </thead>
          <tbody className='w-full'>
            {successData?.length > 0 ? (
              <>
                {successData?.map((item: any, index: any) => {
                  const status =
                    failData && failData.length !== 0 && failData.find((obj: any) => obj.callid === item.callid)

                  return (
                    <tr key={`admin_logs${index}`}>
                      <td>
                        {
                          moment.utc(item?.timestamp).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                          // moment(item?.timestamp).tz(timeZone).format(`${process.env.REACT_APP_DATE_FORMAT} - LT`)
                          // moment
                          //   .tz(moment(item?.timestamp), timeZone)
                          //   .format(` ${process.env.REACT_APP_DATE_FORMAT} - LT`)
                        }
                      </td>
                      <td>{item?.event || '-'}</td>
                      <td>{item?.email || '-'}</td>
                      <td>{customerinfo?.model || '-'}</td>
                      <td>{item?.gw_uid || '-'}</td>
                      <td
                        style={{
                          color: `${
                            selected === 4 || selected === 5
                              ? item.status_ok || item.status_ok === 'true'
                                ? getColorCode('Online')
                                : getColorCode('redToRed')
                              : status
                              ? getColorCode('redToRed')
                              : getColorCode('Online')
                          }`
                        }}
                      >
                        {selected === 4 || selected === 5
                          ? item.status_ok || item.status_ok === 'true'
                            ? 'Success'
                            : 'Failed'
                          : status
                          ? 'Failed'
                          : 'Success'}
                      </td>
                    </tr>
                  )
                })}
              </>
            ) : (
              <tr className='empty-row'>
                <td colSpan={6} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                  No data found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {successData && successData?.length > 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={totalCount}
          rowsPerPage={limit}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}
export default TableData
