/* eslint-disable eqeqeq */
import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HighchartsMore from 'highcharts/highcharts-more'
import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import Loader from '../../../../../components/Loader'
import { useGatewayStatsChart } from '../../../../../hook/useGatewayStats'

import CachedIcon from '@mui/icons-material/Cached'
import CloseIcon from '@mui/icons-material/Close'
import FormControl from '@mui/material/FormControl'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { DatePicker } from 'antd'
import { useSelector } from 'react-redux'
import { CustomersRootState } from '../../../../../redux/customerStatistics/types'

// import { LocalizationProvider } from '@mui/x-date-pickers-pro'
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
// import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker'
// import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField'
// import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { Button } from '@mui/material'
import { useSessionContex } from '../../../../../SessionContex'
import { getColorCode } from '../../../../../utils/helper'
import './telemetry_chart.scss'

const { RangePicker } = DatePicker
dayjs.extend(utc)
dayjs.extend(timezone)

HighchartsMore(Highcharts)

const TelemetryChart = ({ handleTelemetryCancel }: any) => {
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const [LoadAverageData, setLoadAverageData] = useState<any>([])
  const [FilterData, setFilterData] = useState<any>(24)
  const [isDisplayDate, setIsDisplayDate] = useState(false)
  const [isSwitchGraph, setIsSwitchGraph] = useState(true)
  const [isPageLoading, setIsPageLoader] = useState(true)
  // const [selectedDateRange, setSelectedDateRange] = useState<[null, null]>([null, null])
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)

  const [seriesVisibility, setSeriesVisibility] = useState<any>({
    uptime: false,
    memfree: false,
    load_average: true,
    freedata: false,
    wifi_temp0: false,
    wifi_temp1: false,
    wifi_temp2: false,
    // fw_version_num: false,
    fw_version: false
  })
  // let selectedSeries = 'load_average'
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  // const chartfromDate = moment.tz(moment.utc().subtract(FilterData, 'hours'), indiaTimezone).format('YYYYMMDDTHH:mm:ss')

  const chartfromDate = dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(FilterData, 'hours')).format('YYYYMMDDTHH:mm:ss')
  const charttillDate = dayjs.utc(dayjs.tz(dayjs(), timeZone)).format('YYYYMMDDTHH:mm:ss')
  // const charttillDate = moment.tz(moment.utc(), indiaTimezone).format('YYYYMMDDTHH:mm:ss')

  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')

  const [selectedDateRange, setSelectedDateRange] = useState<any>([dayjs().subtract(1, 'days'), dayjs()])

  const { data, isLoading, refetch } = useGatewayStatsChart(
    isDisplayDate ? (startDate !== '' ? startDate : chartfromDate) : chartfromDate,
    isDisplayDate ? (endDate !== '' ? endDate : charttillDate) : charttillDate,
    gw_uid
  )

  useEffect(() => {
    setLoadAverageData([])
    setIsPageLoader(true)
    refetch()
  }, [gw_uid])
  useEffect(() => {
    if (reCall) {
      setLoadAverageData([])
      setIsPageLoader(true)
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isPageLoading) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isPageLoading])

  useEffect(() => {
    FilterData !== 'custom' && refetch()
    FilterData !== 'custom' && setIsPageLoader(true)
  }, [FilterData])

  const handleChange = (event: SelectChangeEvent) => {
    const selectedValue = event.target.value as string
    setStartDate('')
    setEndDate('')
    // setSelectedDateRange([dayjs().subtract(1, 'days'), dayjs()])
    if (selectedValue === 'custom') {
      setIsDisplayDate(true)
      setStartDate(dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(24, 'hours')).format('YYYYMMDDTHH:mm:ss'))
      setEndDate(dayjs.utc(dayjs.tz(dayjs(), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else {
      setIsDisplayDate(false)
    }
    setFilterData(selectedValue)
  }

  const handleAntDateChange = (dates: any, dateStrings: any) => {
    setFilterData('custom')
    if (dates === null) {
      setSelectedDateRange([dayjs().subtract(1, 'days'), dayjs()])
      setStartDate(dayjs().subtract(1, 'days').format('YYYYMMDDTHH:mm:ss'))
      setEndDate(dayjs().format('YYYYMMDDTHH:mm:ss'))
    } else {
      // dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(dataFilter, 'hours')).format('YYYYMMDDTHH:mm:ss.SSSSSS')
      const sD =
        dates && dates[0].isSame(dayjs(), 'day')
          ? dayjs.utc(dayjs.tz(dayjs(dayjs().startOf('day')), timeZone)).format('YYYYMMDDTHH:mm:ss')
          : dayjs.utc(dayjs.tz(dayjs(dateStrings[0]), timeZone)).format('YYYYMMDDTHH:mm:ss')
      const eD = dayjs.utc(dayjs.tz(dayjs(dateStrings[1]), timeZone)).format('YYYYMMDDTHH:mm:ss')
      setSelectedDateRange(dates)
      setStartDate(sD)
      setEndDate(eD)
    }
  }

  const openChangeFun = (open: any) => {
    if (!open) {
      if (selectedDateRange[0].isSame(dayjs(), 'day')) {
        setSelectedDateRange([dayjs().startOf('day'), selectedDateRange[1]])
      } else {
        setSelectedDateRange(selectedDateRange)
      }
    }
  }
  const convertTimestampFormat = (timestamp: any) => {
    const formattedTimestamp = dayjs.utc(timestamp, 'YYYYMMDDTHH:mm:ss').tz(timeZone).toDate()
    return formattedTimestamp
  }

  useEffect(() => {
    if (data) {
      console.log('data', data)
      if (data === 401) {
        !openModal && setOpenModal(true)
      } else {
        if (data) {
          const newArray =
            data &&
            data?.map((dataarray: any) => ({
              ...dataarray,
              timestamp: convertTimestampFormat(dataarray.timestamp)
            }))
          setLoadAverageData(newArray)
        }
      }
    }
    setTimeout(() => {
      setIsPageLoader(false)
    }, 500)
  }, [data])

  // const handleSwitchGraphChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setIsSwitchGraph(event.target.checked)
  // }

  const updateChartVisibility = (name: string) => {
    console.log('name', name)
    const updatedVisibility = { ...seriesVisibility }
    console.log('updatedVisibility', updatedVisibility)
    Object.keys(updatedVisibility).forEach((seriesName: any) => {
      updatedVisibility[seriesName] = isSwitchGraph ? seriesName === name : false
      console.log('updatedVisibility[seriesName]', updatedVisibility[seriesName])
    })
    if (isSwitchGraph || (name && updatedVisibility[name] !== undefined)) {
      updatedVisibility[name] = true
    }
    console.log('updatedVisibility', updatedVisibility)
    setSeriesVisibility(updatedVisibility)
  }

  function fetchDataForMetric(name: any) {
    console.log('name', name, "name == 'fw_version'", name == 'fw_version')
    if (name == 'fw_version') {
      console.log('LoadAverageData', LoadAverageData)
      return LoadAverageData.map((item: any) => {
        const versionNumber = parseFloat(item.fw_version.replace(/\./g, ''))
        // const versionNumber = parseFloat(item.fw_version)
        // const versionSegments = item.fw_version.split('.')
        // const versionNumber = parseFloat(
        //   `${versionSegments[0]}.${versionSegments[1]}${versionSegments[2]}${versionSegments[3]}`
        // )
        return {
          x: item.timestamp.getTime(),
          y: versionNumber, // Use major.minor as the y-axis value
          fw_version: item.fw_version // Store the full version for tooltip
        }
      })
    }

    // Return numeric data for other metrics
    return LoadAverageData?.map((item: any) => [item.timestamp.getTime(), item[name]])
  }

  const generateSeries = (name: any, color: any) => {
    return {
      name: name,
      data: fetchDataForMetric(name),
      color: color,
      pointPlacement: 'on',
      lineWidth: 1,
      visible: seriesVisibility[name],
      events: {
        legendItemClick: function () {
          // selectedSeries = name
          updateChartVisibility(name)
        }
      }
    }
  }

  // const generateSeries = (name: any, color: any) => {
  //   return {
  //     name: name,
  //     data: LoadAverageData?.map((item: any) => ({
  //       x: item.timestamp.getTime(),
  //       y: item[name], // Assuming the property name matches the series name
  //       uptime: item.uptime,
  //       memfree: item.memfree,
  //       load_average: item.load_average,
  //       freedata: item.freedata,
  //       wifi_temp0: item.wifi_temp0,
  //       wifi_temp1: item.wifi_temp1,
  //       wifi_temp2: item.wifi_temp2,
  //       mesh_role: item.mesh_role,
  //       xu_hwlbl: item.xu_hwlbl,
  //       xu_whitelabel: item.xu_whitelabel,
  //       xu_user: item.xu_user,
  //       xu_version: item.xu_version,
  //       fw_version_num: item.fw_version_num,
  //       fw_version: item.fw_version,
  //       max_pid: item.max_pid,
  //       wan_mac: item.wan_mac
  //     })),
  //     color: color,
  //     pointPlacement: 'on',
  //     lineWidth: 1,
  //     visible: seriesVisibility[name],
  //     events: {
  //       legendItemClick: function () {
  //         // selectedSeries = name
  //         updateChartVisibility(name)
  //       }
  //     }
  //   }
  // }

  // Example usage:
  const dynamicSeries = [
    generateSeries('uptime', getColorCode('uptime')),
    generateSeries('memfree', getColorCode('memfree')),
    generateSeries('load_average', getColorCode('load_average')),
    generateSeries('freedata', getColorCode('freedata')),
    generateSeries('wifi_temp0', getColorCode('wifi_temp0')),
    generateSeries('wifi_temp1', getColorCode('wifi_temp1')),
    generateSeries('wifi_temp2', getColorCode('wifi_temp2')),
    generateSeries('fw_version', getColorCode('fw_version_num'))
  ]

  // Your original series array
  // const series = dynamicSeries
  const chartOptions = {
    chart: {
      type: 'line',
      height: 400,
      zoomType: 'x'
    },
    title: {
      enabled: false,
      text: ''
    },
    xAxis: {
      title: {
        text: 'Time'
      },
      type: 'datetime', // Set the type to 'datetime'
      lineWidth: 1,
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject) {
          const momentValue = moment(this.value).local()
          if (FilterData === 24) {
            // Round down to the nearest multiple of 2 hours
            const roundedDownHours = Math.floor(momentValue.hours() / 2) * 2
            const formattedTime = momentValue.hours(roundedDownHours).minutes(0).format('hh:mm A')
            // Check if the formatted time is exactly midnight (00:00)
            if (formattedTime === '00:00') {
              // Display date along with time for midnight
              const formattedDateTime = momentValue.format('MMM D')
              return formattedDateTime
            } else {
              // Display only the time for other hours
              return formattedTime
            }
          } else {
            return momentValue.format('MMM D')
          }
        },
        style: {
          fontSize: '10px' // Set the font size here
        }
      }
    },
    yAxis: {
      title: {
        enabled: true,
        text: 'Values'
      },
      // tickInterval: 1,
      labels: {
        formatter: function (this: Highcharts.AxisLabelsFormatterContextObject): string {
          // Get the series name to check if fw_version is active
          console.log('this.axis.series', this.axis.series)
          const seriesName = this.axis.series[this.axis.series.length - 1].name // Adjust index if necessary

          // If fw_version series is active, return its value as a string
          if (seriesName === 'fw_version') {
            const matchingItem = LoadAverageData.find(
              (item: any) => parseFloat(item.fw_version.replace(/\./g, '')) === this.value
            )
            return matchingItem ? matchingItem.fw_version : this.value.toString()
            // return this.value.toString() // Return the fw_version directly as a string
          }

          const numericValue = this.value as number
          if (Math.abs(numericValue) >= 1000) {
            return Highcharts.numberFormat(numericValue / 1000, 0) + 'k'
          } else {
            return numericValue.toString()
          }
        },
        style: {
          fontSize: '10px' // Set the font size here
        }
      }
    },
    legend: {
      enabled: true,
      itemStyle: {
        fontSize: '12px', // Set the font size for legend items
        borderColor: 'transparent' // Make the border invisible
      },
      labelFormatter: function (this: any) {
        return `<span style="display:inline-block; width: 0; height: 0;"></span> ${this.name}`
      },
      symbol: 'circle', // Set symbol to "circle"
      symbolWidth: 5, // Set symbolWidth to 0
      symbolHeight: 10 // Set symbolHeight to 0
    },
    series: dynamicSeries,
    tooltip: {
      enabled: true,
      useHTML: true,
      followPointer: true, // Make the tooltip follow the cursor
      followTouchMove: true, // Make the tooltip follow touch gestures
      formatter: function (this: any) {
        // const timestampDate = new Date(this.x)
        const timestampDate = moment.utc(this.x).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)

        // let tooltipContent = `<div class="custom-tooltip" style="height: 380px;">`
        let tooltipContent = `<div class="custom-tooltip">`
        tooltipContent += `<span style="font-size: 15px; font-weight: 600; line-height: 1.5;">Timestamp:</span>`
        tooltipContent += `<span style="font-size: 15px; line-height: 1.5;"> ${timestampDate}</span><br/>`

        if (this.point && this.point.options) {
          if (!this.point.options?.fw_version) {
            tooltipContent += `<span style="font-size: 15px; font-weight: 600; line-height: 1.5; color: ${this.series.color};">${this.series.name}:</span>`
            tooltipContent += `<span style="font-size: 15px; line-height: 1.5; color: ${this.series.color};"> ${this.y}</span><br/>`
          }

          const properties = Object.keys(this.point.options).filter(property => property !== 'x' && property !== 'y')
          properties.forEach(property => {
            tooltipContent += `<span style="font-size: 15px; font-weight: 600; line-height: 1.5; color: ${this.series.color};">${property}:</span>`
            tooltipContent += `<span style="font-size: 15px; line-height: 1.5; color: ${this.series.color};"> ${this.point.options[property]}</span><br/>`
          })
        }
        tooltipContent += `</div>`
        return tooltipContent
      }
    },
    credits: {
      enabled: false
    },
    data: dynamicSeries.find(item => item.visible)?.data
  }
  const disabledDate = (current: any) => {
    return current && current?.valueOf() > dayjs()
  }

  return (
    <>
      <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
        <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}>
          Telemetry History
          <CachedIcon
            className='ml-3 cursor-pointer'
            onClick={() => {
              setIsPageLoader(true)
              refetch()
              setTimeout(() => {
                setIsPageLoader(false)
              }, 1000)
            }}
          />
        </h3>
        <CloseIcon
          className='cursor-pointer'
          onClick={() => {
            handleTelemetryCancel()
          }}
        />
      </div>
      <div className='flex flex-col gap-y-2 w-full'>
        <div className='telemetry-chart'>
          <div className='d-flex justify-content-between items-center'>
            <div className='w-25 mt-2'>
              <FormControl fullWidth>
                <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={FilterData}
                  label='Filter'
                  onChange={handleChange}
                  className='text-uppercase'
                >
                  <MenuItem value={24} className='text-uppercase'>
                    Last 24 hours
                  </MenuItem>
                  <MenuItem value={168} className='text-uppercase'>
                    Last 7 Days
                  </MenuItem>
                  <MenuItem value={730} className='text-uppercase'>
                    Last 30 Days
                  </MenuItem>
                  <MenuItem value={2190} className='text-uppercase'>
                    Last 90 Days
                  </MenuItem>
                  <MenuItem value={'custom'} className='text-uppercase'>
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            {isDisplayDate && (
              <div className='d-flex align-items-baseline'>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={['SingleInputDateRangeField']}>
                  <DateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    onChange={handleDateChange}
                    timezone={indiaTimezone}
                    disableFuture
                  />
                </DemoContainer>
              </LocalizationProvider> */}
                <RangePicker
                  onChange={handleAntDateChange}
                  disabledDate={disabledDate}
                  // showTime={{ format: 'HH:mm A' }}
                  format='YYYY-MM-DD hh:mm A'
                  defaultValue={[dayjs().subtract(1, 'days'), dayjs()]}
                  value={selectedDateRange}
                  inputReadOnly
                  onOpenChange={openChangeFun}
                />
                <Button
                  className='ms-3'
                  variant='contained'
                  size='medium'
                  sx={{
                    backgroundColor: getColorCode('greenBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('greenBlue')
                    }
                  }}
                  onClick={() => {
                    startDate !== '' && endDate !== '' && refetch()
                  }}
                >
                  Apply
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className='pt-3'>
          {isPageLoading && <Loader />}
          {!isPageLoading && <HighchartsReact highcharts={Highcharts} options={chartOptions} height={500} />}
          {/* {!isPageLoading && (
            <FormControlLabel
              control={
                <Checkbox
                  defaultChecked
                  onChange={handleSwitchGraphChange}
                  name='gilad'
                  sx={{ '& .MuiSvgIcon-root': { fontSize: 24 } }}
                />
              }
              label='Switch Graph'
            />
          )} */}
        </div>
      </div>
    </>
  )
}

export default TelemetryChart
