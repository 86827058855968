/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import AnimatedLayout from '../../../Layouts/AnimatedLayout'
// import Box from '@mui/material/Box'
import Tab from '@mui/material/Tab'
import Tabs from '@mui/material/Tabs'
// import Typography from '@mui/material/Typography'
import MuiAlert from '@mui/material/Alert'
import Snackbar from '@mui/material/Snackbar'
// import AlertsImage from '../../../common-images/app-logo/customer-images/AlertsImage'
import ConfigurationImage from '../../../common-images/app-logo/customer-images/ConfigurationImage'
import DeviceControlImage from '../../../common-images/app-logo/customer-images/DeviceControlImage'
// import DeviceImage from '../../../common-images/app-logo/customer-images/DeviceImage'
import OverviewImage from '../../../common-images/app-logo/customer-images/OverviewImages'
import QoeImage from '../../../common-images/app-logo/customer-images/QoeImage'
import ServiceImage from '../../../common-images/app-logo/customer-images/ServiceImage'
import SpeedTestImage from '../../../common-images/app-logo/customer-images/SpeedTestImage'
import TopologyImage from '../../../common-images/app-logo/customer-images/TopologyImage'
// import HealthCheckImage from '../../../common-images/app-logo/customer-images/HealthCheckImages'
import { ElementEqual, Global, ShieldSecurity } from 'iconsax-react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router'
import LogsIcon from '../../../common-images/app-logo/customer-images/LogsIcon'
import StatisticsLogo from '../../../common-images/app-logo/customer-images/StatisticsLogo'
import CustomerServices from '../../../components/customers/customer-services/customer-services'
import Provisioning from '../../../components/Device-table/Provisioning'
import { BreadcrumbContext } from '../../../contexts/BreadcrumbContext'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import { getAccountGetWayInfo, getAccountGetWayInfoId } from '../../../redux/deviceStatistics/deviceStatistics'
import { RootState } from '../../../redux/deviceStatistics/types'
import CyberDashboard from '../../Dashboard/cyber-security/dashboard'
import CyberThreats from '../../Dashboard/cyber-security/threats'
import GatewayLogs from '../Logs'
import CustomersOverview from '../Overview'
import QoePage from '../Qoe'
import SpeedTest from '../SpeedTest'
import Statistics from '../Statistics'
// import Devices from '../Devices'
import { DeviceInformationRootState } from '../../../redux/deviceInformation/types'
import Dashboard from '../../Dashboard/parental/dashboard/dashboard'
import Policy from '../../Dashboard/parental/policy/policy'
import Supervision from '../../Dashboard/parental/supervision/supervision'
import ConfigurationPage from '../Configuration'
import DeviceControl from '../DeviceControl'
// import SessionTimeOutModal from '../../../components/SessionTimeOutModal'
import { useSessionContex } from '../../../SessionContex'
import { cleanCurrentGatewayInfoFromSessionStorage, getColorCode } from '../../../utils/helper'
// import { CustomersRootState } from '../../../redux/customerStatistics/types'
// import PageNotFound from '../../PublicPage/PageNotFound'
import Loader from '../../../components/Loader'
// import LoadingSpinner from '../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import ReStatus from '../../../common-images/app-logo/customer-images/ReStatus'
import ReportsPage from '../../Reports'
import TopologyNew from '../TopologyNewBK'

interface SnackBarProps {
  isOpen: boolean
  message: string
  type: 'redToRed' | 'Warning' | 'chartPurpleBlue' | 'Closed'
  severity?: 'error' | 'success'
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
interface TabPanelProps {
  children?: React.ReactNode
  index: number
  value: number
  width?: string
  addPadding?: boolean
}

function TabPanel(props: TabPanelProps) {
  const { addPadding, children, value, index, width = 'auto', ...other } = props
  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      style={{ width: width, paddingTop: addPadding ? '1rem' : '0' }}
      data-testid={`testid_simple_tabpane_${index}`}
    >
      {value === index && <>{children}</>}
    </div>
  )
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function a11yInnerProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

function a11ySecurityProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const CustomersDashboard = () => {
  const { setServiceApi, serviceApi, reCall, setReCall } = useSessionContex()

  const dispatch: any = useDispatch()
  const location = useLocation()
  const deviceInformation = useSelector((state: DeviceInformationRootState) => state.deviceInformation.data)
  const userGateways: any = useSelector((state: RootState) => state.deviceStatistics.userGateways) //Get All user devices data
  const activeValue: any = useSelector((state: RootState) => state.deviceStatistics.activeTab) //Get Active Tab
  const deviceLoader: any = useSelector((state: any) => state.deviceStatistics.deviceGatewayLoading) //Get Active Tab
  const [innerValue, setInnerValue] = useState(0)
  const [securityValue, setSecurityValue] = useState(0)
  const [gwUid, setGwUid] = useState<string>('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [provistionId, setProvistionId] = useState('')
  const [activeDeviceOption, setActiveDeviceOption] = useState<any>({
    label: '',
    Id: ''
  })
  const [mqttResWan, setMqttResWan] = useState()
  const [mqttRes, setMqttRes] = useState<any>()
  const navigate = useNavigate()

  const { data: gatewayInfo, isLoading, isFetching, error, refetch }: any = useGatewayInfo(gwUid) //User device data
  const { updateBreadcrumbData, updateFilters, updateDisabled } = useContext(BreadcrumbContext)
  const { id, email } = useParams()
  const activeGw_Uid: string = useSelector((state: any) => state.customerStatistics.activeGw_Uid)

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })
  useEffect(() => {
    if (!!location.pathname && location.pathname.split('/')[2]) {
      setGwUid(location.pathname.split('/')[2])
      dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: location.pathname.split('/')[2] })
      // setTimeout(() => {
      //   dispatch(getAccountGetWayInfo())
      // }, 5000)
    }
  }, [location])

  useEffect(() => {
    if (gatewayInfo) {
      if (location.search !== '') {
        if (location.state === 'service') {
          setInnerValue(innerValue !== 1 ? 0 : 1)
        } else {
          navigate(`/dashboard/${gatewayInfo?.gw_uid}`)
        }
      }
      if (email) {
        if (location.state === 'service') {
          setSecurityValue(securityValue !== 1 ? 0 : 1)
        } else {
          navigate(`/dashboard/${gatewayInfo?.gw_uid}`)
        }
      }
    }
  }, [location, email, gatewayInfo])
  useEffect(() => {
    setMqttRes('')
    refetch()
  }, [gwUid])

  const [mqtt, setMqtt] = useState<any>(null)
  const [connectedToMqtt, setConnectedToMqtt] = useState<boolean>(false)
  const [activeName, setActiveName] = useState('Overview')

  const initializeMqtt = async () => {
    if (!activeGw_Uid) return
    const JsMqttClientImpl = (await import('../../../mqtt')).JsMqttClientImpl
    setMqtt(new JsMqttClientImpl())
  }
  useEffect(() => {
    if (mqtt !== null) {
      mqtt.disconnect()
    } else {
      setMqttRes('')
      initializeMqtt()
    }
    setActiveName('Overview')
    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 0 })
  }, [gwUid])
  const callMqtt = () => {
    // /router/<Master gwid>/<Satellite gwid>/command
    // /router/${gatewayInfo?.parent_gw_uid}/${gwUid}/command

    let topic = `/router/${gwUid}/command`
    let topic2 = `/router/${gwUid}/resp`
    let topic3 = `/router/${gwUid}/site_survey`
    if (
      process.env.REACT_APP_TITLE === 'Scaling' &&
      gatewayInfo?.dev_mesh_role !== 'CAP' &&
      gatewayInfo?.parent_gw_uid !== ''
    ) {
      topic = `/router/${gatewayInfo?.parent_gw_uid}/${id}/command`
      topic2 = `/router/${gatewayInfo?.parent_gw_uid}/${id}/resp`
      topic3 = `/router/${gatewayInfo?.parent_gw_uid}/${id}/site_survey`
    }
    setMqttRes('')
    mqtt.subscribeToTopics([topic2, topic, topic3])
    const message = JSON.stringify({
      cmd: 'getsysconfig',
      callid: 3,
      response_topic: 'resp',
      op: 'wizard',
      params: [
        'cell_data',
        'wan_ip',
        'lan_ip',
        'access_method',
        'WLAN_SSID_2G',
        'WLAN_password_2G',
        'WLAN_security_2G',
        'WLAN_SSID_5G',
        'WLAN_password_5G',
        'WLAN_security_5G',
        'WLAN_bandwidth_5G',
        'WLAN_channel_5G',
        'WLAN_bandwidth_2G',
        'WLAN_channel_2G',
        'WLAN_same_ssid',
        'mesh_role',
        'timezone_name',
        'sim_apn',
        'lte_4g_bands',
        'lte_5g_bands',
        'lte_nsa_5g_bands'
      ]
    })
    setConnectedToMqtt(false)

    // if (process.env.REACT_APP_TITLE === 'Scaling' && gatewayInfo?.dev_mesh_role !== 'CAP') {
    //   mqtt.sendMessage({ message, topic })
    // }
    // if (gatewayInfo?.dev_mesh_role === 'CAP') {
    mqtt.sendMessage({ message, topic })
    // }
  }
  useEffect(() => {
    if (reCall) {
      setMqttRes('')
      const message = JSON.stringify({
        cmd: 'getsysconfig',
        callid: 3,
        response_topic: 'resp',
        op: 'wizard',
        params: [
          'cell_data',
          'wan_ip',
          'lan_ip',
          'access_method',
          'WLAN_SSID_2G',
          'WLAN_password_2G',
          'WLAN_security_2G',
          'WLAN_SSID_5G',
          'WLAN_password_5G',
          'WLAN_security_5G',
          'WLAN_bandwidth_5G',
          'WLAN_channel_5G',
          'WLAN_bandwidth_2G',
          'WLAN_channel_2G',
          'WLAN_same_ssid',
          'mesh_role',
          'timezone_name',
          'sim_apn',
          'lte_4g_bands',
          'lte_5g_bands',
          'lte_nsa_5g_bands'
        ]
      })
      if (gatewayInfo?.dev_mesh_role === 'CAP') {
        sendMqttMessage(message, activeGw_Uid)
      }

      setTimeout(() => {
        setReCall(false)
      }, 500)
    }
  }, [reCall])
  useEffect(() => {
    // setConnectedToMqtt(false)

    if (gatewayInfo && gatewayInfo?.is_online) {
      mqtt
        ?.connect()
        .then(() => {
          setSnackBarInfo({
            isOpen: true,
            message: 'connected to Router',
            type: 'chartPurpleBlue'
          })
          if (gatewayInfo?.dev_mesh_role === 'CAP') {
            callMqtt()
          }
        })
        .catch((err: any) => {
          mqtt.disconnect()
          setSnackBarInfo({
            isOpen: true,
            message: 'Not connected to Router',
            type: 'redToRed'
          })
          setTimeout(() => {
            initializeMqtt()
          }, 500)
        })
    }
    if (gatewayInfo && !gatewayInfo?.is_online) {
      setMqttRes('Offline')
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mqtt, gatewayInfo])

  useEffect(() => {
    if (mqtt && gatewayInfo?.is_online && !connectedToMqtt) {
      mqtt?.addMessageListener((message: any) => {
        const res = JSON.parse(message)
        if (res && res?.result === 'OK' && (res?.callid === 3 || res?.callid === 356)) {
          console.log('========reqandres', res)
          setMqttRes({ ...mqttRes, ...res })
          setConnectedToMqtt(true)
        }
        if (res.callid === 356 && res.result === 'OK') {
          setSnackBarInfo({
            isOpen: true,
            message: 'Applied new config successfully',
            type: 'Closed'
          })
        }
      })
    }
  }, [mqtt, gatewayInfo])

  const sendMqttMessage = async (
    message: any,
    id: string,
    addParent: string = gatewayInfo?.dev_mesh_role !== 'CAP' ? gatewayInfo?.parent_gw_uid : activeGw_Uid
  ) => {
    let topic = `/router/${id}/command`
    if (process.env.REACT_APP_TITLE === 'Scaling') {
      if (addParent !== id && addParent !== '') {
        topic = `/router/${addParent}/${id}/command`
      } else {
        topic = `/router/${id}/command`
      }
    }
    setConnectedToMqtt(false)
    if (mqtt) {
      try {
        await mqtt.sendMessage({ message, topic })
      } catch (error) {
        console.log('error', error)
      }
    } else {
      initializeMqtt()
      setTimeout(() => {
        mqtt.sendMessage({ message, topic })
      }, 1000)
    }
  }

  useEffect(() => {
    if (!gatewayInfo) return
    if (!isFetching && gatewayInfo) {
      const nav: any = [
        {
          label: 'Home',
          href: '/'
        }
        // {
        //   label: 'Customers',
        //   href: '/dashboard'
        // }
      ]
      if (!!gatewayInfo.email && !!gatewayInfo.gw_uid) {
        nav.push({
          label: `${gatewayInfo.email}`,
          href: `/dashboard/${gatewayInfo.gw_uid}`
        })
      } else {
        nav.push({
          label: (
            <div
              className='provisionLink'
              onClick={(e: any) => {
                e.stopPropagation()
                setProvistionId(gatewayInfo.gw_uid || '')
                setIsModalOpen(true)
              }}
              style={{
                color: getColorCode('infoBlue')
              }}
              data-testid='testid_provision_link'
            >
              Click here to Provision
            </div>
          ),
          href: `/dashboard/${gatewayInfo.gw_uid}`,
          onClick: true
        })
      }

      if (location.state === 'service' && location.search !== '') {
        nav.push(
          { label: 'Parental Control', href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}` },
          {
            label: innerValue === 0 ? 'Dashboard' : innerValue === 1 ? 'Supervision' : 'Policy',
            href: `/dashboard/${gatewayInfo?.gw_uid}?email=${gatewayInfo?.email}`
          }
        )
      }
      if (location.state === 'service' && !!email) {
        nav.push(
          { label: 'Cyber Security', href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}` },
          {
            label: securityValue === 0 ? 'Dashboard' : 'Threats',
            href: `/dashboard/${gatewayInfo?.gw_uid}/${gatewayInfo?.email}`
          }
        )
      }
      updateBreadcrumbData(nav)

      localStorage.setItem('email', gatewayInfo.email) // for get all Devices data
      localStorage.setItem('mac', gatewayInfo.wan_mac)
      localStorage.setItem('gwuid', gatewayInfo.gw_uid)
    } else {
      const nav: any = [
        {
          label: '',
          href: '/'
        }
      ]
      updateBreadcrumbData(nav)
    }
    // setTimeout(() => {
    //   dispatch(getAccountGetWayInfo()) // for get all Devices data
    // }, 5000)

    return () => {
      localStorage.removeItem('email')
    }
  }, [gatewayInfo, securityValue, innerValue, gwUid, location, isFetching])

  useEffect(() => {
    if (!!gatewayInfo) {
      const val =
        gatewayInfo?.title && (gatewayInfo?.title.trim() === '' || gatewayInfo?.title === '')
          ? 'No Title'
          : gatewayInfo?.title

      setActiveDeviceOption({ label: val, Id: gatewayInfo.gw_uid })
      if (gatewayInfo && gatewayInfo.email) {
        dispatch(getAccountGetWayInfo(gatewayInfo.email))
      } else {
        dispatch(getAccountGetWayInfoId(gatewayInfo.gw_uid))
      }
    }
  }, [gwUid, gatewayInfo])

  useEffect(() => {
    if (gatewayInfo) {
      if (userGateways !== undefined && userGateways.length > 0) {
        const firstArrayData = userGateways.filter((res: any) => {
          return res.dev_mesh_role === 'CAP' && res.is_online
        })
        const secondArrayData = userGateways.filter((res: any) => {
          return res.dev_mesh_role !== 'CAP' && res.is_online
        })
        const thirdArrayData: any = userGateways.filter((res: any) => {
          return res.dev_mesh_role === 'CAP' && !res.is_online
        })
        const lastArrayData = userGateways.filter((res: any) => {
          return res.dev_mesh_role !== 'CAP' && !res.is_online
        })

        const filterUpdatedData = [...firstArrayData, ...secondArrayData, ...thirdArrayData, ...lastArrayData]

        const filters: any = [
          {
            filterKey: 'dataFilter',
            options: filterUpdatedData.map((item: any) => {
              const val = item?.title && (item?.title.trim() === '' || item?.title === '') ? 'No Title' : item?.title
              return { label: `${val}`, Id: `${item.gw_uid}` }
            }) as any,
            useLowerCase: true,
            tags: filterUpdatedData.map((item: any) => {
              return (
                <div className='d-flex'>
                  {item?.dev_mesh_role === 'CAP' ? (
                    <div
                      className={'master_tag'}
                      style={{ background: getColorCode('blueBlue'), color: getColorCode('white') }}
                    >
                      Master
                    </div>
                  ) : (
                    <div
                      className={'satellite_tag'}
                      style={{ border: `1px solid ${getColorCode('blueBlue')}`, color: getColorCode('blueBlue') }}
                    >
                      Satellite
                    </div>
                  )}
                  {item?.is_online ? (
                    <div
                      className={'online_tag'}
                      style={{ background: getColorCode('darkGreenToGreen'), color: getColorCode('white') }}
                    >
                      Online
                    </div>
                  ) : (
                    <div
                      className={'offline_tag'}
                      style={{ border: `1px solid ${getColorCode('grayToGray')}`, color: getColorCode('grayToGray') }}
                    >
                      Offline
                    </div>
                  )}
                </div>
              )
            }) as any[],
            activeOption: activeDeviceOption as any,
            action: (e: string, opt: any) => {
              localStorage.setItem('gwuid', opt.Id)
              if (e === 'dataFilter') {
                setActiveDeviceOption(opt || 'No Title')
                if (!!opt.Id) {
                  dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: opt.Id })
                  localStorage.setItem('gwuid', opt.Id)
                } else {
                  dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: '' })
                  cleanCurrentGatewayInfoFromSessionStorage()
                }
              }
            }
          }
        ]
        if (gatewayInfo && location.pathname.split('/')[2] === gwUid && activeDeviceOption.Id === gwUid) {
          updateFilters(filters)
        }
      } else if (userGateways !== undefined && userGateways.length === 0) {
        const val =
          gatewayInfo?.title && (gatewayInfo?.title.trim() === '' || gatewayInfo?.title === '')
            ? 'No Title'
            : gatewayInfo?.title
        const filters: any = [
          {
            filterKey: 'dataFilter',
            options: [{ label: `${val}` || 'No title', Id: `${gatewayInfo.gw_uid}` }],
            useLowerCase: true,
            tags: [
              <div className='d-flex'>
                {gatewayInfo?.dev_mesh_role === 'CAP' ? (
                  <div
                    className={'master_tag'}
                    style={{ background: getColorCode('blueBlue'), color: getColorCode('white') }}
                  >
                    Master
                  </div>
                ) : (
                  <div
                    className={'satellite_tag'}
                    style={{ border: `1px solid ${getColorCode('blueBlue')}`, color: getColorCode('blueBlue') }}
                  >
                    Satellite
                  </div>
                )}
                {gatewayInfo?.is_online ? (
                  <div
                    className={'online_tag'}
                    style={{ background: getColorCode('darkGreenToGreen'), color: getColorCode('white') }}
                  >
                    Online
                  </div>
                ) : (
                  <div
                    className={'offline_tag'}
                    style={{ border: `1px solid ${getColorCode('grayToGray')}`, color: getColorCode('grayToGray') }}
                  >
                    Offline
                  </div>
                )}
              </div>
            ],
            activeOption: activeDeviceOption as any,
            action: (e: string, opt: any) => {
              localStorage.setItem('gwuid', opt.Id)
              if (e === 'dataFilter') {
                setActiveDeviceOption(opt || 'No Title')
                if (!!opt.Id) {
                  dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: opt.Id })
                  localStorage.setItem('gwuid', opt.Id)
                } else {
                  dispatch({ type: 'SET_CUSTOMER_GWUID_REQUEST', payload: '' })
                  cleanCurrentGatewayInfoFromSessionStorage()
                }
              }
            }
          }
        ]
        if (location.pathname.split('/')[2] === gwUid) {
          updateFilters(filters)
        }
      }
    }
  }, [userGateways, activeDeviceOption, gatewayInfo, deviceLoader])

  useEffect(() => {
    if (isFetching) {
      updateDisabled(isFetching)
    } else {
      setTimeout(() => {
        updateDisabled(isFetching)
      }, 1000)
    }
  }, [isFetching])
  useEffect(() => {
    if (gatewayInfo && gatewayInfo.length === 0) {
      navigate(`/dashboard`)
    }
  }, [isFetching, gatewayInfo])

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    if (newValue !== 4) {
      dispatch({ type: 'SET_DEVICE_MAC_ID_REQUEST', payload: '' })
    }
    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: newValue })
    setActiveName(tabNewArray[newValue].name)
  }

  const handleInnerChange = (event: React.SyntheticEvent, newValue: number) => {
    setInnerValue(newValue)
  }

  const handleChangeSecurity = (event: React.SyntheticEvent, newValue: number) => {
    setSecurityValue(newValue)
  }

  const navigateFirst = () => {
    setActiveName('Overview')
    dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: 0 })
  }

  // got index issue so I have created a array in useEffect you can change the logic like remove some object currently it's static.
  const tabArray: any = [
    {
      name: 'Overview',
      // ReceiptSearch
      image: <OverviewImage />,
      panel: <CustomersOverview mqttRes={mqttRes} callMqtt={callMqtt} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_overview_tab'
    },
    {
      name: 'Topology',
      //       <Hierarchy3
      //  size="32"
      //  color="#FF8A65"
      //  variant="Bold"
      // />
      image: <TopologyImage />,
      panel: <TopologyNew serialNumber={gwUid} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_topology_tab'
    },
    {
      name: 'QoE',
      image: <QoeImage />,
      panel: <QoePage />,
      testId: 'testid_qoe_tab'
    },
    {
      name: 'Speed Test',
      image: <SpeedTestImage />,
      panel: <SpeedTest mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_speed_test_tab'
    },
    // {
    //   name: 'Alerts',
    //   image: <AlertsImage />,
    //   panel: <Alerts />,
    //   testId: 'testid_speed_test_tab',
    // },
    {
      name: 'Statistics',
      image: <StatisticsLogo />,
      panel: <Statistics />,
      testId: 'testid_statistics_tab'
    },
    {
      name: 'Services',
      image: <ServiceImage />,
      panel: <CustomerServices />,
      testId: 'testid_services_tab'
    },
    {
      name: 'Logs',
      image: <LogsIcon />,
      panel: <GatewayLogs />,
      testId: 'testid_logs_tab'
    },
    {
      name: 'Device Control',
      image: <DeviceControlImage />,
      panel: <DeviceControl mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} callMqtt={callMqtt} />,
      testId: 'testid_device_control_tab'
    },
    {
      name: 'Configuration',
      image: <ConfigurationImage />,
      panel: <ConfigurationPage mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} navigateFirst={navigateFirst} />,
      testId: 'testid_configuration_tab'
    },
    {
      name: 'Reports',
      image: <ReStatus />,
      panel: <ReportsPage />,
      testId: 'testid_reports_tab'
    }
  ]
  const tabNoCapArray: any = [
    {
      name: 'Overview',
      image: <OverviewImage />,
      panel: <CustomersOverview mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_overview_tab'
    },
    {
      name: 'Speed Test',
      image: <SpeedTestImage />,
      panel: <SpeedTest mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_speed_test_tab'
    },
    {
      name: 'Statistics',
      image: <StatisticsLogo />,
      panel: <Statistics />,
      testId: 'testid_statistics_tab'
    },

    {
      name: 'Logs',
      image: <LogsIcon />,
      panel: <GatewayLogs />,
      testId: 'testid_logs_tab'
    },
    {
      name: 'Device Control',
      image: <DeviceControlImage />,
      panel: <DeviceControl mqttRes={mqttRes} sendMqttMessage={sendMqttMessage} />,
      testId: 'testid_device_control_tab'
    }
  ]
  const [tabNewArray, setTabNewArray] = useState<any>(tabArray)
  useEffect(() => {
    if (gatewayInfo) {
      console.log("gatewayInfo==>", gatewayInfo)
      if (gatewayInfo?.dev_mesh_role === 'CAP') {
        const filterArray = tabArray.filter((obj: any) => obj.name !== 'Services')
        const newData = gatewayInfo?.email === '' ? filterArray : tabArray
        const reportArray = newData.filter((res: any) => res.testId !== 'testid_reports_tab')
        setTabNewArray(process.env.REACT_APP_MODE !== 'hitron' ? reportArray : newData)
        const abc = newData.findIndex((obj: any) => obj['name'] === activeName)
        dispatch({ type: 'SET_DEVICE_ACTIVE_TAB_REQUEST', payload: abc === -1 ? 0 : abc })
      } else {
        setTabNewArray(tabNoCapArray)
      }
    }
  }, [gatewayInfo, mqttRes])

  return (
    <>
      {isFetching ? (
        <Loader />
      ) : (
        <AnimatedLayout>
          <Snackbar
            open={snackBarInfo.isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={() =>
              setSnackBarInfo({
                isOpen: false,
                message: snackBarInfo.message,
                type: snackBarInfo.type
              })
            }
            sx={{ backgroundColor: 'transparent' }}
          >
            <div>
              <Alert
                severity='success'
                sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
              >
                {snackBarInfo.message}
              </Alert>
            </div>
          </Snackbar>
          <div className='customers_wrapper'>
            <div className='custom_main_wrapper'>
              <div className='customer_header_wrapper flex items-center justify-between pr-[20px]'>
                {gatewayInfo &&
                  (location.state === 'service' && location.search !== '' ? (
                    <Tabs
                      orientation='horizontal'
                      variant='scrollable'
                      aria-label='basic tabs example'
                      onChange={handleInnerChange}
                      value={innerValue}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: getColorCode('blueBlue')
                        }
                      }}
                      sx={{
                        '.Mui-selected': {
                          color: `${getColorCode('blueBlue')} !important`
                        }
                      }}
                    >
                      <Tab
                        label={
                          <div className='d-flex tab-div'>
                            <ElementEqual />
                            <label className='tab-label'>Dashboard</label>
                          </div>
                        }
                        {...a11yInnerProps(0)}
                        className='customer-tab'
                        disabled={!serviceApi}
                      />
                      <Tab
                        label={
                          <div className='d-flex tab-div'>
                            <Global />
                            <label className='tab-label'>Supervision</label>
                          </div>
                        }
                        {...a11yInnerProps(1)}
                        className='customer-tab'
                        disabled={!serviceApi}
                      />
                      <Tab
                        label={
                          <div className='d-flex tab-div'>
                            <ShieldSecurity />
                            <label className='tab-label'>Policy</label>
                          </div>
                        }
                        {...a11yInnerProps(2)}
                        className='customer-tab'
                        disabled={!serviceApi}
                      />
                    </Tabs>
                  ) : location.state === 'service' && email !== undefined ? (
                    <Tabs
                      orientation='horizontal'
                      variant='scrollable'
                      aria-label='basic tabs example'
                      onChange={handleChangeSecurity}
                      value={securityValue}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: getColorCode('blueBlue')
                        }
                      }}
                      sx={{
                        '.Mui-selected': {
                          color: `${getColorCode('blueBlue')} !important`
                        }
                      }}
                    >
                      <Tab
                        label={
                          <div className='d-flex tab-div'>
                            <ElementEqual />
                            <label className='tab-label'>Dashboard</label>
                          </div>
                        }
                        {...a11ySecurityProps(0)}
                        className='customer-tab'
                        disabled={!serviceApi}
                      />
                      <Tab
                        label={
                          <div className='d-flex tab-div'>
                            <Global />
                            <label className='tab-label'>Threats</label>
                          </div>
                        }
                        {...a11ySecurityProps(1)}
                        className='customer-tab'
                        disabled={!serviceApi}
                      />
                    </Tabs>
                  ) : (
                    <Tabs
                      orientation='horizontal'
                      variant='scrollable'
                      aria-label='basic tabs example'
                      onChange={handleChange}
                      value={activeValue}
                      TabIndicatorProps={{
                        style: {
                          backgroundColor: getColorCode('blueBlue')
                        }
                      }}
                      sx={{
                        '.Mui-selected': {
                          color: `${getColorCode('blueBlue')} !important`,
                          path: {
                            fill: `${getColorCode('blueBlue')} !important`
                          }
                        }
                      }}
                    >
                      {tabNewArray.map((obj: any, index: number) => {
                        return (
                          <Tab
                            key={index}
                            label={
                              <div className='d-flex tab-div'>
                                {obj?.image}
                                <label className='tab-label'>{obj?.name}</label>
                              </div>
                            }
                            {...a11yProps(index)}
                            className='customer-tab'
                            data-testid={obj?.testId}
                          />
                        )
                      })}
                    </Tabs>
                  ))}
              </div>
              {location.state === 'service' && (location.search !== '' || email !== undefined) && (
                <button
                  type='button'
                  onClick={() => {
                    localStorage.removeItem('isPCverified')
                    setServiceApi(false)
                    setSecurityValue(0)
                    setInnerValue(0)
                    navigate(`/dashboard/${id}`)
                  }}
                  className=' text-white px-16 py-2 rounded-[5px] cursor-pointer exitbutton'
                  style={{
                    background: getColorCode('infoBlue')
                  }}
                >
                  Exit {location.search !== '' ? 'Parental' : email !== undefined ? 'Cyber' : 'Home'}
                </button>
              )}
            </div>
            <div className='d-flex justify-center mt-[10px !important] pe-4 ps-4 pb-4'>
              <div className={`w-full`}>
                {gatewayInfo &&
                  (location.state === 'service' && location.search !== '' ? (
                    <>
                      <TabPanel value={innerValue} index={0} addPadding={true}>
                        <Dashboard />
                      </TabPanel>
                      <TabPanel value={innerValue} index={1} addPadding={true}>
                        <Supervision />
                      </TabPanel>
                      <TabPanel value={innerValue} index={2} addPadding={true}>
                        <Policy />
                      </TabPanel>
                    </>
                  ) : location.state === 'service' && email !== undefined ? (
                    <>
                      <TabPanel value={securityValue} index={0} addPadding={true}>
                        <CyberDashboard />
                      </TabPanel>
                      <TabPanel value={securityValue} index={1} addPadding={true}>
                        <CyberThreats />
                      </TabPanel>
                    </>
                  ) : (
                    <>
                      {tabNewArray.map((obj: any, index: any) => {
                        return (
                          <TabPanel
                            value={activeValue}
                            index={index}
                            key={index}
                            addPadding={obj.name === 'Topology' ? false : true}
                          >
                            {obj.panel}
                          </TabPanel>
                        )
                      })}
                    </>
                  ))}
              </div>
            </div>
          </div>
          {isModalOpen && (
            <Provisioning
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
              provistionId={provistionId}
              refetch={refetch}
            />
          )}
        </AnimatedLayout>
      )}
    </>
  )
}

export default CustomersDashboard
