import moment from 'moment'
import { useGatewayThreatsLogs } from '../../../../hook/useGatewayLogs'
import Loader from '../../../../components/Loader'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import TablePagination from '@mui/material/TablePagination'
import EmailIcon from '@mui/icons-material/Email'
import { useSelector } from 'react-redux'
import { ChangeEvent, useState, useEffect } from 'react'
import { Snackbar, } from '@mui/material'
import { getColorCode } from '../../../../utils/helper'
import { SnackBarProps } from '../../../../components/Device-table/Provisioning'
import { useSessionContex } from '../../../../SessionContex'
import MuiAlert from '@mui/material/Alert'
import { useCyberSendReportRequest } from '../../../../hook/cyber-security/useCyberSecurity'
import { useGatewayInfo } from '../../../../hook/useGatewayInfo'
import { useParams } from 'react-router-dom'
import { Tooltip } from 'antd'

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}
const GatewaySecurityLogs = () => {
  const gw_uid = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const { id: serialNumber } = useParams()
  // @ts-ignore
  const { data: gatewayInfo } = useGatewayInfo(serialNumber as any)
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const { data, isLoading, refetch, isFetching } = useGatewayThreatsLogs(gw_uid, limit, currentPage)
  const { mutate: sendReportMutate, error: emailError, isSuccess: emailSuccess } = useCyberSendReportRequest()
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })

  useEffect(() => {
    refetch()
  }, [currentPage, limit, offset])

  useEffect(() => {
    setCurrentPage(0)
    setOffset(0)
    refetch()
  }, [gw_uid])
  useEffect(() => {
    if (reCall) {
      if (currentPage !== 0 || offset !== 0) {
        setCurrentPage(0)
        setOffset(0)
      } else {
        refetch()
      }
    }
  }, [reCall])

  useEffect(() => {
    if (!(isLoading || isFetching)) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isLoading, isFetching])

  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])

  useEffect(() => {
    if (emailError !== null) {
      setSnackBarInfo({
        isOpen: true,
        message: emailError,
        type: 'redToRed',
        severity: 'error'
      })
    }
  }, [emailError])
  useEffect(() => {
    if (emailSuccess) {
      setSnackBarInfo({
        isOpen: true,
        message: 'Report Send Successfully',
        type: 'chartPurpleBlue'
      })
    }
  }, [emailSuccess])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }

  return (
    <div>
      {isLoading || isFetching ? (
        <div className='d-flex w-full justify-center aline-center'>
          <Loader />
        </div>
      ) : (
        <table className='pc-table w-full'>
          <thead>
            <tr>
              <td>Intrusion URL</td>
              <td>Name</td>
              <td>Ip</td>
              <td>Time</td>
              {process.env.REACT_APP_MODE !== 'hitron' && <td></td>}
            </tr>
          </thead>
          <tbody>
            {/* {!!data?.rows && data?.rows?.length > 0 ? (
              data?.rows.map((item: any, index: number) => { */}
            {!!data && data?.length > 0 ? (
              data?.map((item: any, index: number) => {
                return (
                  <tr key={`security_logs_${index}`}>
                    <td>{item?.intrusion_url}</td>
                    <td>{item?.sig_name}</td>
                    <td>{item?.src_ip || '-'}</td>
                    <td className='cursor-pointer'>
                      <Tooltip
                        color='#707e8c'
                        placement='top'
                        title={
                          moment.utc(item?.attack_date).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                          // moment
                          // .tz(moment.utc(item?.attack_date), timeZone)
                          // .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm`)
                        }
                      >
                        <> {moment.utc(item?.attack_date).local().fromNow() || '-'}</>
                        {/* {moment.tz(moment.utc(item?.attack_date), timeZone).fromNow()} */}
                      </Tooltip>
                    </td>
                    {process.env.REACT_APP_MODE !== 'hitron' && (
                      <td>
                        <EmailIcon
                          onClick={async () => {
                            const res: any = await sendReportMutate({
                              payload: item,
                              email: gatewayInfo.email,
                              device_id: item?.device_id
                            })
                          }}
                          style={{ cursor: 'pointer' }}
                        />
                      </td>
                    )}
                  </tr>
                )
              })
            ) : (
              <tr className='empty-row'>
                <td colSpan={4} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                  Security logs not found
                </td>
              </tr>
            )}
          </tbody>
        </table>
      )}
      {/* {!isLoading && !isFetching && data && data?.rows && data?.rows.length > 0 && ( */}
      {!isLoading && !isFetching && data && data?.length > 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={data?.total_threats_count || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
      {snackBarInfo.isOpen && (
        <Snackbar
          open={snackBarInfo.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() =>
            setSnackBarInfo({
              isOpen: false,
              message: snackBarInfo.message,
              type: snackBarInfo.type,
              severity: 'success'
            })
          }
          sx={{ backgroundColor: 'transparent' }}
        >
          <div>
            <Alert
              severity={snackBarInfo.severity}
              sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
            >
              {snackBarInfo.message}
            </Alert>
          </div>
        </Snackbar>
      )}
    </div>
  )
}
export default GatewaySecurityLogs
