import { Box, Button, Card, CardContent, Checkbox, FormControlLabel, Snackbar, Typography } from '@mui/material'
import MuiAlert from '@mui/material/Alert'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SnackBarProps } from '../../../../components/Device-table/Provisioning'
import { CustomersRootState } from '../../../../redux/customerStatistics/types'
import { getColorCode } from '../../../../utils/helper'
import './index.scss'
import RenderWiFiSection from './RenderWiFiSection'

export type WiFiSection = 'wifi24' | 'wifi5'
type WiFiSettings = {
  ssid: string
  encrypt: string
  password: string
}
export type Settings = {
  wifi24: WiFiSettings
  wifi5: WiFiSettings
  dualFrequencyIntegration: boolean
}
export type SettingsKey = keyof WiFiSettings

type ValidationErrors = {
  wifi24: {
    ssid: string
    encrypt: string
    password: string
  }
  wifi5: {
    ssid: string
    encrypt: string
    password: string
  }
}

function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const WifiBasicSettings = ({
  mqttRes,
  sendMqttMessage,
  setFetchingRouterSettings
}: {
  mqttRes: any
  sendMqttMessage: any
  setFetchingRouterSettings: any
}) => {
  const activeGw_Uid: string = useSelector((state: CustomersRootState) => state.customerStatistics.activeGw_Uid)
  const [showPassword24, setShowPassword24] = useState(false)
  const [showPassword5, setShowPassword5] = useState(false)
  const [passwordModified, setPasswordModified] = useState({})
  const [settings, setSettings] = useState<Settings>({
    wifi24: {
      ssid: '',
      encrypt: '',
      password: ''
    },
    wifi5: {
      ssid: '',
      encrypt: '',
      password: ''
    },
    dualFrequencyIntegration: false
  })

  const [errors, setErrors] = useState<ValidationErrors>({
    wifi24: {
      ssid: '',
      encrypt: '',
      password: ''
    },
    wifi5: {
      ssid: '',
      encrypt: '',
      password: ''
    }
  })

  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed'
  })

  useEffect(() => {
    if (mqttRes?.params) {
      setSettings({
        wifi24: {
          ssid: mqttRes.params.WLAN_SSID_2G || '',
          encrypt: mqttRes.params.WLAN_security_2G || '',
          password: mqttRes.params.WLAN_password_2G || ''
        },
        wifi5: {
          ssid: mqttRes.params.WLAN_SSID_5G || '',
          encrypt: mqttRes.params.WLAN_security_5G || '',
          password: mqttRes.params.WLAN_password_5G || ''
        },
        dualFrequencyIntegration:
          process.env.REACT_APP_REALM === 'Customer' ? (mqttRes?.params?.WLAN_same_ssid === '1' ? true : false) : false
      })
    }
  }, [mqttRes])

  const validateSSID = (ssid: string): string => {
    if (!ssid) {
      return 'SSID is required.'
    }
    if (ssid.length < 1) {
      return 'SSID must be at least 1 character long.'
    }
    if (ssid.length > 32) {
      return 'SSID must not exceed 32 characters.'
    }
    // const ssidRegex = /^[a-zA-Z0-9][a-zA-Z0-9_-]*(?<!\s)$/
    // const ssidRegex = /^[a-zA-Z0-9][a-zA-Z0-9!@#$%^&*()_+\-={}[\]|\\:;<>?/,.]*[^\s]$/
    const ssidRegex =
      process.env.REACT_APP_REALM === 'Customer'
        ? /^[a-zA-Z0-9][a-zA-Z0-9!@#$%^&*()_+\-={}[\]|\\:;<>?/,.]*[^'""\s]$/
        : /^[a-zA-Z0-9][a-zA-Z0-9_-]*(?<!\s)$/
    if (!ssidRegex.test(ssid)) {
      return process.env.REACT_APP_REALM === 'Customer'
        ? 'Up to 32 alphanumeric, case sensitive, characters. First character must be a letter or number, Tab and trailing whitespace cannot be used.'
        : 'SSID can only contain first character must be a letter or number.'
    }
    return ''
  }

  const validatePassword = (password: string, isEncrypted: boolean): string => {
    if (isEncrypted) {
      if (!password) {
        return 'Password is required.'
      }
      if (password.length < 8) {
        return 'Password must be at least 8 characters long.'
      }
      if (password.length > 63) {
        return 'Password must not exceed 63 characters.'
      }
    }
    return ''
  }

  const validateSettings = (): boolean => {
    let isValid = true
    const newErrors: ValidationErrors = {
      wifi24: { ssid: '', password: '', encrypt: '' },
      wifi5: { ssid: '', password: '', encrypt: '' }
    }

    const validateSection = (section: WiFiSection) => {
      // SSID validation
      const ssidError = validateSSID(settings[section].ssid)
      if (ssidError) {
        newErrors[section].ssid = ssidError
        isValid = false
      }

      // Encrypt field validation
      if (!settings[section].encrypt) {
        newErrors[section].encrypt = 'Encryption type is required.'
        isValid = false
      }

      // Password validation for encrypted connections
      const isEncrypted = settings[section].encrypt !== 'none'
      const passwordError = validatePassword(settings[section].password, isEncrypted)
      if (passwordError) {
        newErrors[section].password = passwordError
        isValid = false
      }
    }

    if (settings.dualFrequencyIntegration) {
      validateSection('wifi24')
    } else {
      validateSection('wifi24')
      validateSection('wifi5')
    }

    setErrors(newErrors)
    return isValid
  }

  const handleDualFrequencyChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSettings(prev => ({
      ...prev,
      dualFrequencyIntegration: event.target.checked
    }))
    setErrors({
      wifi24: { ssid: '', password: '', encrypt: '' },
      wifi5: { ssid: '', password: '', encrypt: '' }
    })
  }

  const handleChange = (section: WiFiSection, field: SettingsKey, value: string): void => {
    if (field === 'password') {
      setPasswordModified(prevState => ({
        ...prevState,
        [section]: true
      }))
    }

    setSettings(prev => {
      const newSettings = settings.dualFrequencyIntegration
        ? {
            ...prev,
            wifi24: {
              ...prev.wifi24,
              [field]: value
            },
            wifi5: {
              ...prev.wifi5,
              [field]: value
            }
          }
        : {
            ...prev,
            [section]: {
              ...prev[section],
              [field]: value
            }
          }
      let fieldError = ''
      if (field === 'ssid') {
        fieldError = validateSSID(value)
      } else if (field === 'password') {
        const isEncrypted = newSettings[section].encrypt !== 'none'
        fieldError = validatePassword(value, isEncrypted)
      }

      setErrors(prevErrors => ({
        ...prevErrors,
        [section]: {
          ...prevErrors[section],
          [field]: fieldError
        }
      }))

      return newSettings
    })
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault()

    if (!validateSettings()) {
      setSnackBarInfo({
        isOpen: true,
        message: 'Please correct the form errors before submitting',
        type: 'redToRed'
      })
      return
    }

    const params: {
      WLAN_SSID_2G: string
      WLAN_security_2G: string
      WLAN_SSID_5G: string
      WLAN_security_5G: string
      WLAN_password_2G?: string
      WLAN_password_5G?: string
      WLAN_same_ssid?: string
    } = {
      WLAN_SSID_2G: settings.wifi24.ssid,
      WLAN_security_2G: settings.wifi24.encrypt,
      WLAN_SSID_5G: settings.wifi5.ssid,
      WLAN_security_5G: settings.wifi5.encrypt,
      WLAN_same_ssid: settings.dualFrequencyIntegration === true ? '1' : '0'
    }

    if (settings.wifi24.encrypt !== 'none') {
      params.WLAN_password_2G = settings.wifi24.password
    }
    if (settings.wifi5.encrypt !== 'none') {
      params.WLAN_password_5G = settings.wifi5.password
    }

    const routerConfigPayload = JSON.stringify({
      cmd: 'setsysconfig',
      callid: 3,
      response_topic: 'resp',
      params: params
    })

    sendMqttMessage(routerConfigPayload, activeGw_Uid)
    setSnackBarInfo({
      isOpen: true,
      message: 'Wi-Fi Basic Setting Applied Successfully',
      type: 'Closed'
    })
    setPasswordModified({})
    setTimeout(() => {
      setFetchingRouterSettings(true)
    }, 1000)
  }

  return (
    <>
      <form onSubmit={handleSubmit}>
        {process.env.REACT_APP_REALM === 'Customer' && (
          <Box className='bg-white info-card-dev mb-4 pb-2'>
            <Box className='font-bold  title pt-2 pl-10 border-b-2 pb-2 mb-2'>Setting WiFi Configuration</Box>
            <Card sx={{ boxShadow: 'none', mt: 2, mb: 1 }}>
              <CardContent style={{ padding: 0 }}>
                <FormControlLabel
                  labelPlacement='start'
                  label='Dual frequency integration'
                  control={
                    <Checkbox checked={settings.dualFrequencyIntegration} onChange={handleDualFrequencyChange} />
                  }
                  sx={{ ml: 2, fontWeight: 'bold' }}
                />
              </CardContent>
            </Card>
          </Box>
        )}
        {settings.dualFrequencyIntegration ? (
          <>
            <RenderWiFiSection
              type='wifi24'
              showPassword={showPassword24}
              setShowPassword={setShowPassword24}
              settings={settings}
              errors={errors}
              handleChange={handleChange}
              passwordModified={passwordModified}
            />
          </>
        ) : (
          <>
            <RenderWiFiSection
              type='wifi24'
              showPassword={showPassword24}
              setShowPassword={setShowPassword24}
              settings={settings}
              errors={errors}
              handleChange={handleChange}
              passwordModified={passwordModified}
            />
            <RenderWiFiSection
              type='wifi5'
              showPassword={showPassword5}
              setShowPassword={setShowPassword5}
              settings={settings}
              errors={errors}
              handleChange={handleChange}
              passwordModified={passwordModified}
            />
          </>
        )}
        <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, marginBottom: 5 }}>
          <Button
            type='submit'
            variant='contained'
            sx={{
              background: getColorCode('infoBlue'),
              color: '#fff',
              '&:hover': {
                background: getColorCode('infoBlue')
              }
            }}
          >
            Save
          </Button>
        </Box>
      </form>
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert
            severity={snackBarInfo.type === 'redToRed' ? 'error' : 'success'}
            sx={{
              color: getColorCode('white'),
              background: getColorCode(snackBarInfo.type)
            }}
          >
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
    </>
  )
}

export default WifiBasicSettings
