import * as React from 'react'
import { FC, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import { Lock1, Unlock } from 'iconsax-react'
import { ConnectedDevice } from '../../../models/Xcpem'
import { getColorCode } from '../../../utils/helper'
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord'
// import { handlefingerprint } from '../../../utils/Teame/fingerprinting'
import { RootState } from '../../../redux/deviceStatistics/types'
import { DashboardRootState } from '../../../redux/dashboard/types'
import { newhandleNetworkHealthMapFingerPrinting } from '../../../utils/fingerprinting'

interface Props {
  devices: any[]
  onChange: (deviceId: any) => void
  useMac?: boolean
  useDeviceInfo?: boolean
  setActiveTile: any
  activeTile: string | number
  displayLock?: boolean
}

const ThreatsTile: FC<Props> = ({
  devices,
  onChange,
  useMac,
  useDeviceInfo,
  activeTile,
  setActiveTile,
  displayLock = true
}): any => {
  const activeDeviceMacId: any = useSelector((state: RootState) => state.deviceStatistics.activeDeviceMacId) //Get Active MAD ID
  const dispatch: ThunkDispatch<DashboardRootState, unknown, AnyAction> = useDispatch()
  const filterData = devices.filter((i: any) => {
    return i?.mesh_role === null
  })
  useEffect(() => {
    if (!!activeDeviceMacId) {
      setActiveTile(activeDeviceMacId.device_id || activeDeviceMacId.mac)
      // onchange(activeDeviceMacId.device_id || activeDeviceMacId.mac)
    } else if (!!filterData[0]?.mac) {
      dispatch({ type: 'SET_DEVICE_MAC_ID_REQUEST', payload: filterData[0] })
      setActiveTile(filterData[0]?.mac)
    }
  }, [activeDeviceMacId])

  useEffect(() => {
    if (!activeDeviceMacId && !!devices[0]?.mac) {
      setActiveTile(filterData[0]?.mac)
    }
  }, [devices])

  const handleActiveTile = (deviceId: any) => {
    if (deviceId === '*') {
      setActiveTile('*')
    } else if (useDeviceInfo) {
      onChange(deviceId)
    } else {
      setActiveTile(deviceId?.device_id || deviceId?.mac)
      if (useMac) {
        onChange(deviceId?.device_macid)
      } else {
        onChange(deviceId?.device_id || deviceId?.mac)
      }
    }
  }

  // const allTile = (
  //   <div
  //     key='threat_Tile_all'
  //     className={`device-wrapper justify-center align-center w-full ${
  //       activeTile === '*' ? 'active-border' : 'in-active-border'
  //     }`}
  //     onClick={() => handleActiveTile('*')}
  //   >
  //     <span
  //       className={`ooverflow-hidden text-center overflow-ellipsis whitespace-nowrap min-w-[110px] w-full
  //           ${
  //             activeTile === '*' ? 'text-alerts-critical font-sf-regular' : 'font-sf-regular text-layout-textOnSurface'
  //           }`}
  //     >
  //       All Devices
  //     </span>
  //   </div>
  // )
  const tiles =
    devices.length !== 0
      ? [
          // allTile,
          ...devices
            // ?.sort((a, b) => (a.is_active === b.is_active ? 0 : a.is_active ? -1 : 1))
            .map((tile: ConnectedDevice, index: number) => {
              const isactiveTile = !!activeTile ? activeTile === (tile?.mac || tile?.device_id) : index === 0
              const cls = `device-wrapper 	 text-center relative justify-center ${
                tile?.mesh_role === null ? 'cursor-pointer' : ''
              } `
              // const fingerprintPayload = [
              //   tile?.os_name,
              //   tile?.device_name,
              //   tile?.fingerbank_device_name,
              //   tile?.os_parent_name,
              //   tile?.manufacturer_name
              // ]
              // const folder: any = process.env.REACT_APP_TITLE

              // const image = tile?.model?.toLowerCase()?.includes('aria')
              //   ? 'aria'
              //   : handlefingerprint(fingerprintPayload)

              const image =
                tile.mesh_role === 'master'
                  ? 'mainWifi.png'
                  : tile.mesh_role === 'satellite'
                  ? 'satellite.png'
                  : tile?.model?.toLowerCase()?.includes('aria')
                  ? 'aria.png'
                  : process.env.REACT_APP_REALM === 'Hitron-staging'
                  ? `${
                      tile?.device_icon !== ''
                        ? tile?.device_icon !== 'unknown'
                          ? tile?.device_icon
                          : 'mobile2x'
                        : 'mobile2x'
                    }.png`
                  : `${newhandleNetworkHealthMapFingerPrinting([
                      tile?.os_name,
                      tile?.device_name,
                      tile?.fingerbank_device_name,
                      tile?.os_parent_name,
                      tile?.manufacturer_name
                    ])}2x.png`

              return (
                <div
                  className={cls}
                  style={{
                    border:
                      isactiveTile === true
                        ? `2px solid ${getColorCode('greenBlue')}`
                        : `2px solid ${getColorCode('lightgreenBorderlightBlue')}`
                  }}
                >
                  <span className='font-bold capitalize	'>{tile?.mesh_role !== null ? tile?.mesh_role : ''}</span>
                  <div
                    key={`threat_Tile_${index}`}
                    className={'flex'}
                    onClick={() => {
                      if (tile?.mesh_role === null) {
                        handleActiveTile(tile)
                      }
                    }}
                  >
                    <div className='device-image'>
                      <img
                        // src={`/images/network-map-${
                        //   folder.toLowerCase() !== 'xunison' ? 'hitron-images' : 'images'
                        // }/${image}.png`}
                        src={`/images/network-map-new-hitron-images/${image}`}
                        alt={`${tile?.device_name}`}
                      />
                    </div>
                    <span
                      className={`d-flex overflow-hidden w-full ps-3 align-center overflow-ellipsis whitespace-wrap min-w-[110px]  
                         ${isactiveTile === true ? `font-sf-regular` : 'font-sf-regular text-layout-textOnSurface'}`}
                      style={{
                        color: isactiveTile === true ? `${getColorCode('redToBlue')}` : `${getColorCode('grayToGray')}`
                      }}
                    >
                      {tile?.host || tile?.device_name || tile?.title || '-'}
                      {/* tile?.device_name !== null ? '/' : ''
                          } ${tile?.device_name !== null ? tile?.device_name : ''}`} */}
                    </span>
                    {/* <span className=' justify-center align-center d-flex'>
                      <FiberManualRecordIcon
                        sx={{
                          color:
                            tile?.is_active === true
                              ? getColorCode('Closed')
                              : tile?.is_active === false
                              ? getColorCode('Critical')
                              : getColorCode('Closed'),
                          verticalAlign: 'bottom'
                        }}
                      />
                    </span> */}
                    {displayLock ? (
                      <span
                        className={`absolute top-1 right-1 ${
                          process.env.REACT_APP_REALM === 'Hitron-staging'
                            ? tile.blocked_categories_count === 0 &&
                              tile.blocked_socials_count === 0 &&
                              tile.blocked_domains_count === 0 &&
                              tile.internet_blocked_till === null &&
                              tile.internet_enabled === true &&
                              tile.internet_restrictions_schedule_enabled === false
                              ? `${process.env.REACT_APP_MODE === 'hitron' ? 'hitron-text' : 'text-alerts-critical'}`
                              : 'text-alerts-info'
                            : tile?.is_pc_active === true
                            ? 'text-alerts-info'
                            : tile?.is_pc_active === false
                            ? `${process.env.REACT_APP_MODE === 'hitron' ? 'hitron-text' : 'text-alerts-critical'}`
                            : ''
                        }`}
                      >
                        {process.env.REACT_APP_REALM === 'Hitron-staging' ? (
                          tile.blocked_categories_count === 0 &&
                          tile.blocked_socials_count === 0 &&
                          tile.blocked_domains_count === 0 &&
                          tile.internet_blocked_till === null &&
                          tile.internet_enabled === true &&
                          tile.internet_restrictions_schedule_enabled === false ? (
                            <Unlock size={18} />
                          ) : (
                            <Lock1 size={18} />
                          )
                        ) : tile?.is_pc_active === true ? (
                          <Lock1 size={18} />
                        ) : tile?.is_pc_active === false ? (
                          <Unlock size={18} />
                        ) : (
                          ''
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )
            })
        ]
      : []

  return tiles
}

export default ThreatsTile
