// import TablePagination from '@mui/material/TablePagination'
// import { CloseCircle, TickCircle } from 'iconsax-react'
// import moment from 'moment'
// import { ChangeEvent, FC, useEffect, useState } from 'react'
// import { useWebMonitoring, useWebMonitoringTotal } from '../../../hook/parental-control'
// import { currentISOTime, getColorCode, startOfMonth, startOfWeek } from '../../../utils/helper'
// import WebSuperVisionTile from '../web-supervision/web-supervision-tile'
// import SocialPieChart from '../charts/social-supervision-pie-chart'
// import './styles.scss'
// import LoadingSpinner from '../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
// import { Box } from '@mui/material'
// import dayjs from 'dayjs'
// import SessionTimeOutModal from '../../SessionTimeOutModal'
// import { useSessionContex } from '../../../SessionContex'

// interface Props {
//   email: string
//   dataFilter: 'today' | 'thisWeek' | 'thisMonth'
//   deviceFilter: any
//   showSocialChart: boolean
//   fromDate?: any
// }

// const SupervisionTable: FC<Props> = ({ email, dataFilter, deviceFilter, showSocialChart, fromDate }) => {
//   const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

//   // pagination
//   const [offset, setOffset] = useState<number>(0)
//   const [limit, setLimit] = useState<number>(6)
//   const [currentPage, setCurrentPage] = useState<number>(0)
//   const [loading, setLoading] = useState(true)
//   const [row, setRow] = useState<any>([])
//   const [filterData, setFilterData] = useState<any>([])
//   const [webSelected, setWebSelected] = useState<string>('')

//   // const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
//   const { data, refetch, isFetching } = useWebMonitoring(
//     fromDate || currentISOTime(),
//     email,
//     deviceFilter,
//     offset,
//     limit
//   )
//   const {
//     data: chartData,
//     isFetching: chartFetching,
//     refetch: chartRefetch
//   }: any = useWebMonitoringTotal(fromDate || currentISOTime(), email, deviceFilter)
//   useEffect(() => {
//     if (data) {
//       if (data === 401) {
//         !openModal && setOpenModal(true)
//       } else {
//         setRow(data[0].data)
//       }
//     } else {
//       setRow([])
//     }
//     !isFetching &&
//       setTimeout(() => {
//         setLoading(false)
//       }, 1000)
//   }, [data, isFetching])

//   useEffect(() => {
//     if (row) {
//       setFilterData(webSelected === '' ? row : row?.filter((obj: any) => obj.category_name === webSelected))
//     }
//   }, [row])

//   useEffect(() => {
//     setLoading(true)
//     setWebSelected('')
//     setCurrentPage(0)
//     setOffset(0)
//     refetch()
//     chartRefetch()
//   }, [deviceFilter, dataFilter, fromDate])
//   useEffect(() => {
//     refetch()
//   }, [offset, limit])

//   useEffect(() => {
//     if (reCall) {
//       setLoading(true)
//       setWebSelected('')
//       if (currentPage === 0 || offset === 0) {
//         refetch()
//       } else {
//         setCurrentPage(0)
//         setOffset(0)
//       }
//       chartRefetch()
//     }
//   }, [reCall])

//   useEffect(() => {
//     if (!isFetching) {
//       if (reCall) {
//         setTimeout(() => {
//           setReCall(false)
//         }, 500)
//       }
//     }
//   }, [isFetching])

//   const handleChangePage = (event: unknown, newPage: number) => {
//     setCurrentPage(newPage)
//     const newOffset = newPage * limit
//     setOffset(newOffset)
//   }

//   const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
//     // setRowsPerPage(+event.target.value)
//     setLimit(parseInt(event.target.value))
//     setOffset(0)
//     setCurrentPage(0)
//   }
//   const onWebClick = (selected: any) => {
//     setWebSelected(webSelected !== selected.title ? selected.title : '')
//     setCurrentPage(0)
//     setOffset(0)
//     setFilterData(
//       webSelected !== selected.title ? row?.filter((obj: any) => obj.category_name === selected.title) : row
//     )
//   }

//   const sum = chartData?.reduce((accumulator: any, item: any) => accumulator + item.visit_count, 0) || 1

//   return (
//     <div className='bg-white table-main-wrapper'>
//       <div className='overflow-x-auto pb-3 mb-[16px] px-6 py-4 table-inner-wrapper'>
//         {chartFetching ? (
//           <>
//             {Array.from({ length: 8 }, (_, index) => {
//               return (
//                 <WebSuperVisionTile
//                   key={`supervision_loading_tile_${index}`}
//                   value={'-' as unknown as number}
//                   percentage={100}
//                   title={'-'}
//                   animate={true}
//                 />
//               )
//             })}
//           </>
//         ) : chartData && chartData?.length !== 0 ? (
//           chartData
//             ?.sort((a: any, b: any) => b.visit_count - a.visit_count)
//             .map((item: any, index: number) => {
//               return (
//                 <WebSuperVisionTile
//                   key={`supervision_tile_${index}`}
//                   value={item.visit_count}
//                   percentage={Math.ceil((item.visit_count / sum) * 100)}
//                   title={item.category_name}
//                   onClick={() => onWebClick(item)}
//                   active={webSelected}
//                 />
//               )
//             })
//         ) : (
//           <Box color={getColorCode('redToBlue')} margin={'auto'}>
//             Web chart count not found
//           </Box>
//         )}
//       </div>

//       {showSocialChart && (
//         <SocialPieChart email={email} deviceFilter={deviceFilter} dataFilter={dataFilter} fromDate={fromDate} />
//       )}

//       <table className='pc-table'>
//         <thead>
//           <tr>
//             <td>Allowed</td>
//             <td>Visit Counts</td>
//             <td>Category</td>
//             <td>Website</td>
//             <td>Device Name</td>
//             <td>Time</td>
//           </tr>
//         </thead>
//         <tbody>
//           {(isFetching || loading) && (
//             <tr>
//               <td colSpan={6} style={{ width: '100%' }}>
//                 <Box display={'flex'} justifyContent={'center'}>
//                   <LoadingSpinner />
//                 </Box>
//                 {/* <span className='animate-pulse mx-auto'>Loading</span> */}
//               </td>
//             </tr>
//           )}

//           {!isFetching &&
//             !loading &&
//             filterData &&
//             filterData?.length !== 0 &&
//             filterData
//               ?.sort((a: any, b: any) => {
//                 if (a.start_datetime > b.start_datetime) return -1
//                 if (a.start_datetime < b.start_datetime) return 1
//                 return 0
//               })
//               // ?.slice(offset, offset + limit)
//               .map((row: any) => {
//                 const time = moment
//                   .utc(row.start_datetime)
//                   .local()
//                   .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
//                 // moment(row.start_datetime).format('D.M.YYYY hh:mm A').split(' ')

//                 return (
//                   <tr>
//                     <td>
//                       {/* {row.title.includes('blocked website') || row.title.includes('Failed') ? ( */}
//                       {row.is_website_blocked ? (
//                         <CloseCircle size='32' color={getColorCode('redToYellow')} variant='Bold' />
//                       ) : (
//                         <TickCircle size='32' color={getColorCode('greenToGreen')} variant='Bold' />
//                       )}
//                     </td>
//                     <td>{row.visit_count || 0}</td>
//                     <td>{row.category_name || '-'}</td>
//                     <td>{row.hostname || '-'}</td>
//                     <td>{row.device_name || 'Unknown'}</td>
//                     <td>
//                       {time}
//                       {/* {time[0]}
//                       <br />
//                       {time[1] + ' ' + time[2]} */}
//                     </td>
//                   </tr>
//                 )
//               })}
//           {!isFetching && !loading && filterData?.length === 0 && (
//             <tr>
//               <td colSpan={6} align='center'>
//                 <Box display={'flex'} justifyContent={'center'} textAlign={'center'} color={getColorCode('redToBlue')}>
//                   No Data found
//                 </Box>
//               </td>
//             </tr>
//           )}
//         </tbody>
//       </table>
//       {!isFetching && data && (data[0].data || data[0].data !== null) && data[0].data.length !== 0 && (
//         <TablePagination
//           sx={{ marginRight: 0 }}
//           rowsPerPageOptions={[3, 6, 10, 20, 50]}
//           component='div'
//           count={(data && data[1]?.total?.split('/')[1]) || 0}
//           rowsPerPage={limit}
//           page={currentPage}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       )}
//     </div>
//   )
// }

// export default SupervisionTable

import SupervisionTableCommon from '../common/table/supervisionTable'
import SupervisionTableStaging from '../staging/table/supervisionTable'

const SupervisionTable: React.FC<any> = ({ email, dataFilter, deviceFilter, showSocialChart, fromDate }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' ? (
        <SupervisionTableStaging
          email={email}
          dataFilter={dataFilter}
          deviceFilter={deviceFilter}
          showSocialChart={showSocialChart}
          fromDate={fromDate}
        />
      ) : (
        <SupervisionTableCommon
          email={email}
          dataFilter={dataFilter}
          deviceFilter={deviceFilter}
          showSocialChart={showSocialChart}
          fromDate={fromDate}
        />
      )}
    </>
  )
}

export default SupervisionTable
