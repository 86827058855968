import React, { createContext, useContext, useState, ReactNode } from 'react'
import { useNavigate } from 'react-router-dom'

interface AuthContextType {
  token: string
  login: (newToken: string) => void
  logout: () => void
  isAuthenticated: boolean
}

const AuthContext = createContext<AuthContextType | undefined>(undefined)

export function useAuth(): AuthContextType {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}

interface AuthProviderProps {
  children: ReactNode
}

const AuthProvider = ({ children }: AuthProviderProps) => {
  const [token, setToken] = useState<string>(localStorage.getItem('access_token') || '')

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false)

  const login = (newToken: string) => {
    setToken(newToken)
    localStorage.setItem('access_token', newToken)
  }

  const logout = () => {
    setToken('')
    localStorage.clear()
    setIsAuthenticated(false)
  }

  const authContextValue: AuthContextType = {
    token,
    login,
    logout,
    isAuthenticated
  }

  return <AuthContext.Provider value={authContextValue}>{children}</AuthContext.Provider>
}

export default AuthProvider
