import { applyMiddleware, combineReducers, createStore } from 'redux'
import { persistReducer, persistStore } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // default: localStorage
import { thunk } from 'redux-thunk'
import customerStatisticsReducer from './customerStatistics/reducer'
import dashboardCountReducer from './dashboard/reducers'
import dashboardProblemsReducer from './dashboardProblems/reducers'
import deviceInformationReducer from './deviceInformation/reducers'
import deviceStatisticsReducer from './deviceStatistics/reducer'
import globalSearchReducer from './GlobalSearch/reducer'
import networkHealthMapReducer from './networkHealthMap/reducer'

const persistConfig = {
  key: 'root', // key for the root of the state object
  storage // storage engine (e.g., localStorage)
}

const rootReducer = combineReducers<Partial<any>>({
  dashboardCount: dashboardCountReducer,
  dashboardProblems: dashboardProblemsReducer,
  deviceInformation: deviceInformationReducer,
  globalSearch: globalSearchReducer,
  deviceStatistics: deviceStatisticsReducer,
  customerStatistics: customerStatisticsReducer,
  networkHealthMap: networkHealthMapReducer
})

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = createStore(persistedReducer, applyMiddleware(thunk))

const persistor = persistStore(store)

export { persistor, store }
