import { useContext, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AnyAction } from 'redux'
import { ThunkDispatch } from 'redux-thunk'
import AnimatedLayout from '../../Layouts/AnimatedLayout'
import AllCustomer from '../../common-images/app-logo/card-logo/AllCustomer'
import AllDevices from '../../common-images/app-logo/card-logo/AllDevices'
import Offline from '../../common-images/app-logo/card-logo/Offline'
import Online from '../../common-images/app-logo/card-logo/Online'
import NeedUpdate from '../../common-images/app-logo/card-logo/needUpdate'
import NotRegistered from '../../common-images/app-logo/card-logo/notRegistered'
import CustomerTable from '../../components/Customer-table'
import DeviceTable from '../../components/Device-table'
import { BreadcrumbContext } from '../../contexts/BreadcrumbContext'
import { getCustomerStatisticsCount } from '../../redux/customerStatistics/customerStatistics'
import { CustomersRootState } from '../../redux/customerStatistics/types'
import { getDeviceCount } from '../../redux/deviceStatistics/deviceStatistics'
import { RootState } from '../../redux/deviceStatistics/types'
import { getColorCode } from '../../utils/helper'
import WeeklyRoundedBarChart from './WeeklyRoundedBarChart'

import Grid from '@mui/material/Grid2'
import { useSessionContex } from '../../SessionContex'
import { versionCheckURL } from '../../app-config'
import xcpemSec from '../../axiosInstances/xcpemSec'
import Devices from '../../common-images/app-logo/HitronHomePgTilesIcons/Devices'
import NetWorkLogo from '../../common-images/app-logo/HitronHomePgTilesIcons/NetWorkLogo'
import NotRegisteredHitron from '../../common-images/app-logo/HitronHomePgTilesIcons/NotRegisteredHitron'
import OfflineHitron from '../../common-images/app-logo/HitronHomePgTilesIcons/OfflineHitron'
import OnlineHitron from '../../common-images/app-logo/HitronHomePgTilesIcons/OnlineHitron'
import Update from '../../common-images/app-logo/HitronHomePgTilesIcons/Update'
import Users from '../../common-images/app-logo/HitronHomePgTilesIcons/Users'
import SessionTimeOutModal from '../../components/SessionTimeOutModal'
import { useGetCustomerAndCount } from '../../hook/useAllCustomers'
import VersionCheckModel from '../Login/VersionCheckModel'
import CellularModal from '../Customer/Overview/CellularUsage/CellularModal'

const Customers = () => {
  const whiteLable: any = localStorage.getItem('whiteLabel')
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const dispatch: ThunkDispatch<RootState, unknown, AnyAction> = useDispatch()
  const { updateBreadcrumbData, updateFilters } = useContext(BreadcrumbContext)

  const deviceStats: any[] = useSelector((state: RootState) => state.deviceStatistics.data) || []
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const deviceCount: any = useSelector((state: RootState) => state.deviceStatistics.deviceCount) || []

  // const deviceStatsCount: any[] = useSelector((state: RootState) => state.deviceStatistics.totalData) || []
  const deviceStatsCountLoading = useSelector((state: RootState) => state.deviceStatistics.countLoading) || false
  const deviceStatsTableLoading = useSelector((state: RootState) => state.deviceStatistics.tableLoading) || false
  const customersStatsLoading = useSelector((state: CustomersRootState) => state.customerStatistics.loading) || false
  const customersStatsTotalData = useSelector((state: CustomersRootState) => state.customerStatistics.totalData) || []

  const { data: countData, isFetching, refetch } = useGetCustomerAndCount()
  // const { data: onlineData, isFetching: onLineFetching, refetch: onLineRefetch } = useGetOnlineCount()

  const [filteringValue, setFilteringValue] = useState<string>('')
  const [showVerifyVersionOpen, setShowVerifyVersionOpen] = useState(false)
  // const [onlineDevices, setOnlineDevices] = useState<any>(deviceCount && deviceCount[0]?.online_devices_count)
  // const [offlineDevices, setOfflineDevices] = useState<any>(deviceCount && deviceCount[0]?.offline_devices_count)
  // const [needUpdate, setNeedUpdate] = useState<any>(deviceCount && deviceCount[0]?.update_required_devices_count)
  // const [notRegistered, setNotRegistered] = useState<any>(deviceCount && deviceCount[0]?.not_registered_devices_count)

  const tableData = (filterValue: string) => {
    switch (filterValue) {
      case 'AllCustomer':
        return deviceCount[0]?.all_white_label_count
      case 'AllDevices':
        return deviceCount[0]?.all_devices_count
      case 'Online':
        return deviceCount[0]?.online_devices_count
      case 'Offline':
        return deviceCount[0]?.offline_devices_count
      case 'notRegistered':
        return deviceCount[0]?.not_registered_devices_count
      case 'needUpdate':
        return deviceCount[0]?.update_required_devices_count
    }
  }

  // useEffect(() => {
  //   if (deviceCount.length > 0) {
  //     setOnlineDevices(deviceCount[0]?.online_devices_count)
  //     setOfflineDevices(deviceCount[0]?.offline_devices_count)
  //     setNeedUpdate(deviceCount[0]?.update_required_devices_count)
  //     setNotRegistered(deviceCount[0]?.not_registered_devices_count)
  //   }
  // }, [deviceCount])
  const apiCallFun = async () => {
    if (filteringValue === 'AllCustomer') {
      refetch()
      const res = await dispatch(getCustomerStatisticsCount())
      if (res === 401) {
        !openModal && setOpenModal(true)
      }
    }
    const deviceCountRes = await dispatch(getDeviceCount())
    if (deviceCountRes === 401) {
      !openModal && setOpenModal(true)
    }
  }
  useEffect(() => {
    updateBreadcrumbData([])
    updateFilters([])

    // onLineRefetch()

    refetch()
  }, [])
  useEffect(() => {
    if (filteringValue !== '') {
      updateBreadcrumbData([])
      updateFilters([])
      // if (filteringValue === 'Online') {
      //   onLineRefetch()
      // }
      apiCallFun()
    }
  }, [filteringValue])

  useEffect(() => {
    const val: string = !whiteLable || whiteLable === 'undefined' ? 'AllCustomer' : 'AllDevices'
    setFilteringValue(val)
  }, [whiteLable])

  useEffect(() => {
    if (reCall) {
      updateBreadcrumbData([])
      updateFilters([])
      apiCallFun()
      refetch()
      // onLineRefetch()
    }
  }, [reCall])
  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  const handleFilteringValueChange = (field: string) => {
    setFilteringValue(field)
  }
  useEffect(() => {
    if (countData === 401) {
      !openModal && setOpenModal(true)
    }
  }, [countData])

  const handleCheckVersionFunction = async () => {
    try {
      const response = await xcpemSec.get(versionCheckURL)
      if (!response.data?.valid) {
        setShowVerifyVersionOpen(true)
      } else {
        setShowVerifyVersionOpen(false)
      }
    } catch (error: any) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    (process.env.REACT_APP_REALM === 'Hitron-staging' || process.env.REACT_APP_REALM === 'Customer') &&
      handleCheckVersionFunction()
  }, [])

  const [cellularModalOpen, setCellularModalOpen] = useState<boolean>(false)

  return (
    <AnimatedLayout>
      {openModal && <SessionTimeOutModal />}
      <div className='pt-3 mb-2 pb-3 bg-white text-lg font-bold text-center border-b-2 sticky top-0 left-0 z-10'>
        Managed CPE Dashboard
      </div>
      <Grid container spacing={3} p={3}>
        {/* {process.env.REACT_APP_REALM === 'Scalingtests' && (
          <Grid
            className='cursor-pointer'
            onClick={() => {
              // handleFilteringValueChange('DataUsage')
              setCellularModalOpen(true)
            }}
            sx={{ minHeight: '100%' }}
            size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
          >
            <WeeklyRoundedBarChart
              className={`AllDevices${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              data={10}
              title={'Data Usage'}
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              style={{
                height: '100%',
                border: `2px solid ${filteringValue === 'AllDevices' ? getColorCode('infoBlue') : 'transparent'}`
              }}
              Logo={<NetWorkLogo />}
              testId='testid_all_devices'
            />
          </Grid>
        )} */}
        {(!whiteLable || whiteLable === 'undefined') && (
          <Grid
            className='cursor-pointer'
            onClick={() => {
              handleFilteringValueChange('AllCustomer')
            }}
            sx={{ minHeight: '100%' }}
            size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
          >
            <WeeklyRoundedBarChart
              className={`AllCustomer${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
              data={deviceCount[0].all_white_label_count || 0}
              title={'All Customers'}
              isLoading={deviceStatsCountLoading || customersStatsLoading}
              style={{
                height: '100%',
                border: `2px solid ${filteringValue === 'AllCustomer' ? getColorCode('orangeBlue') : 'transparent'}`
              }}
              Logo={process.env.REACT_APP_MODE === 'hitron' ? <Users /> : <AllCustomer />}
              testId='testid_all_customers'
            />
          </Grid>
        )}
        <Grid
          className='cursor-pointer'
          onClick={() => {
            handleFilteringValueChange('AllDevices')
          }}
          sx={{ minHeight: '100%' }}
          size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
        >
          <WeeklyRoundedBarChart
            className={`AllDevices${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
            data={deviceCount[0].all_devices_count || 0}
            title={process.env.REACT_APP_MODE === 'hitron' ? 'All' : 'All Devices'}
            isLoading={deviceStatsCountLoading || customersStatsLoading}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'AllDevices' ? getColorCode('infoBlue') : 'transparent'}`
            }}
            Logo={process.env.REACT_APP_MODE === 'hitron' ? <Devices /> : <AllDevices />}
            testId='testid_all_devices'
          />
        </Grid>
        <Grid
          className='cursor-pointer'
          onClick={() => {
            handleFilteringValueChange('Online')
          }}
          sx={{ minHeight: '100%' }}
          size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
        >
          <WeeklyRoundedBarChart
            className={`Online${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
            data={deviceCount[0].online_devices_count || 0}
            title={process.env.REACT_APP_MODE === 'hitron' ? 'Online' : 'Online Devices'}
            isLoading={deviceStatsCountLoading || customersStatsLoading}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'Online' ? getColorCode('greenToGreen') : 'transparent'}`
            }}
            Logo={process.env.REACT_APP_MODE === 'hitron' ? <OnlineHitron /> : <Online />}
            subData={deviceCount[0]}
            testId='testid_online_devices'
          />
        </Grid>
        <Grid
          className='cursor-pointer'
          onClick={() => {
            handleFilteringValueChange('Offline')
          }}
          sx={{ minHeight: '100%' }}
          size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
        >
          <WeeklyRoundedBarChart
            className={`Offline${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
            data={deviceCount[0].offline_devices_count || 0}
            title={process.env.REACT_APP_MODE === 'hitron' ? 'Offline' : 'Offline Devices'}
            isLoading={deviceStatsCountLoading || customersStatsLoading}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'Offline' ? getColorCode('redToGray') : 'transparent'}`
            }}
            Logo={process.env.REACT_APP_MODE === 'hitron' ? <OfflineHitron /> : <Offline />}
            testId='testid_offline_devices'
          />
        </Grid>
        <Grid
          className='cursor-pointer'
          onClick={() => {
            handleFilteringValueChange('notRegistered')
          }}
          sx={{ minHeight: '100%' }}
          size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
        >
          <WeeklyRoundedBarChart
            className={`notRegistered${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
            data={deviceCount[0].not_registered_devices_count || 0}
            title={process.env.REACT_APP_MODE === 'hitron' ? 'Not Registered  ' : 'Not Registered Devices'}
            isLoading={deviceStatsCountLoading || customersStatsLoading}
            style={{
              height: '100%',
              border: `2px solid ${
                filteringValue === 'notRegistered' ? getColorCode('lightblueToGray') : 'transparent'
              }`
            }}
            Logo={process.env.REACT_APP_MODE === 'hitron' ? <NotRegisteredHitron /> : <NotRegistered />}
            testId='testid_not_registered_devices'
          />
        </Grid>
        <Grid
          className='cursor-pointer'
          onClick={() => {
            handleFilteringValueChange('needUpdate')
          }}
          sx={{ minHeight: '100%' }}
          size={{ xs: 12, sm: 12, md: 4, lg: 4 }}
        >
          <WeeklyRoundedBarChart
            className={`needUpdate${process.env.REACT_APP_MODE === 'hitron' ? '-hitron' : ''}`}
            data={deviceCount[0].update_required_devices_count || 0}
            title={process.env.REACT_APP_MODE === 'hitron' ? 'Required Update' : 'Update is Available'}
            isLoading={deviceStatsCountLoading || customersStatsLoading}
            style={{
              height: '100%',
              border: `2px solid ${filteringValue === 'needUpdate' ? getColorCode('darkGrayToGray') : 'transparent'}`
            }}
            Logo={process.env.REACT_APP_MODE === 'hitron' ? <Update /> : <NeedUpdate />}
            testId='testid_update_avilable'
          />
        </Grid>
      </Grid>
      <div className=' '>
        <div className='' style={{ width: '100%' }}>
          {filteringValue === 'AllCustomer' ? (
            <CustomerTable
              filteringValue={filteringValue}
              customersStats={customersStatsTotalData}
              customersStatsLoading={customersStatsLoading}
              count={countData ? countData : []}
            />
          ) : (
            <DeviceTable
              filteringValue={filteringValue}
              deviceStats={deviceStats}
              deviceStatsLoading={deviceStatsTableLoading}
              count={tableData(filteringValue)}
            />
          )}
        </div>
      </div>
      <VersionCheckModel isOpen={showVerifyVersionOpen} setShowVerifyVersionOpen={setShowVerifyVersionOpen} />
      <CellularModal
        cellularModalOpen={cellularModalOpen}
        setCellularModalOpen={setCellularModalOpen}
        isDashboard={true}
      />
    </AnimatedLayout>
  )
}

export default Customers
